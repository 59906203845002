
import { defineComponent } from 'vue'

// Composables
import { useProject } from '@/org/use-projects'

// Components
import ForceReloadBtn from '@/components/date/ForceReloadBtn.vue'
import DistroIcons from '@/components/DistroIcons.vue'
import SoftwareIcons from '@/components/SoftwareIcons.vue'
import HelpLinks from '@/components/HelpLinks.vue'

export default defineComponent({
  name: 'HelpCard',
  components: { ForceReloadBtn, DistroIcons, SoftwareIcons, HelpLinks },

  props: {
    loading: {
      type: Boolean,
      default: false,
    },
  },

  setup() {
    const project = useProject()
    return { project }
  },
})
