
import colors from 'vuetify/lib/util/colors'
import { defineComponent, computed, PropType } from 'vue'

// Composables
import { parseParts } from '@/use/uql'
import { UseDateRange } from '@/use/date-range'
import { useTimeseries, useStyledTimeseries } from '@/metrics/use-query'
import { MetricAlert, AlertState } from '@/alerting/use-alerts'

// Components
import UptraceQueryChip from '@/components/UptraceQueryChip.vue'
import MetricChart from '@/metrics/MetricChart.vue'
import MetricMonitorTrigger from '@/alerting/MetricMonitorTrigger.vue'

// Utils
import { MetricColumn } from '@/metrics/types'
import { SystemName, AttrKey } from '@/models/otel'

export default defineComponent({
  name: 'MetricAlertCard',
  components: {
    UptraceQueryChip,
    MetricChart,
    MetricMonitorTrigger,
  },

  props: {
    dateRange: {
      type: Object as PropType<UseDateRange>,
      required: true,
    },
    alert: {
      type: Object as PropType<MetricAlert>,
      required: true,
    },
  },

  setup(props, ctx) {
    const monitor = computed(() => {
      return props.alert.params.monitor
    })

    const columnMap = computed((): Record<string, MetricColumn> => {
      return {
        [monitor.value.column]: {
          unit: monitor.value.columnUnit,
          color: colors.blue.lighten1,
        },
      }
    })

    const timeseries = useTimeseries(() => {
      return {
        ...props.dateRange.axiosParams(),
        metric: monitor.value.metrics.map((m) => m.name),
        alias: monitor.value.metrics.map((m) => m.alias),
        query: monitor.value.query,
      }
    })
    const styledTimeseries = useStyledTimeseries(
      computed(() => timeseries.items),
      columnMap,
      computed(() => ({})),
    )

    const queryParts = computed(() => {
      const tables = []
      for (let metric of monitor.value.metrics) {
        tables.push(`${metric.name} as $${metric.alias}`)
      }

      return parseParts(`${monitor.value.query} | from ${tables.join(', ')}`)
    })

    const spansRoute = computed(() => {
      const where = monitor.value.query.split(' | ').filter((part) => part.startsWith('where '))
      if (!where.length) {
        return undefined
      }

      return {
        name: 'SpanList',
        query: {
          system: SystemName.all,
          query: [
            AttrKey.spanCountPerMin,
            AttrKey.spanErrorPct,
            `{p50,p90,p99}(${AttrKey.spanDuration})`,
            ...where,
          ].join(' | '),
        },
      }
    })

    return {
      AlertState,

      queryParts,
      timeseries,
      styledTimeseries,
      spansRoute,
    }
  },
})
