
import { truncate } from 'lodash-es'
import { defineComponent, computed, PropType } from 'vue'

// Components
import XPct from '@/components/XPct.vue'

// Utilities
import { Unit } from '@/util/fmt'
import { ColoredSystem } from '@/models/colored-system'

export default defineComponent({
  name: 'SpanSystemBarChart',
  components: { XPct },

  props: {
    loading: {
      type: Boolean,
      default: false,
    },
    systems: {
      type: Array as PropType<ColoredSystem[]>,
      required: true,
    },
  },

  setup(props) {
    const totalDuration = computed(() => {
      return props.systems.reduce((acc, system) => {
        return acc + system.duration
      }, 0)
    })

    const styledSystems = computed(() => {
      return props.systems.map((system) => {
        return {
          ...system,
          barStyle: {
            width: pct(system.duration, totalDuration.value),
            'background-color': system.color,
          },
        }
      })
    })

    return {
      Unit,

      totalDuration,
      styledSystems,

      truncate,
    }
  },
})

function pct(a: number, b: number) {
  if (b === 0 || a >= b) {
    return '100%'
  }
  const pct = (a / b) * 100
  return pct + '%'
}
