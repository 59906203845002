
import { defineComponent, computed, PropType } from 'vue'

// Utilities
import { AttrKey, isEventSystem } from '@/models/otel'
import { AttrMap } from '@/models/span'

// Composables
import { UseDateRange } from '@/use/date-range'

// Components
import CodeOrText from '@/components/CodeOrText.vue'
import KeyValueFilterLink from '@/tracing/KeyValueFilterLink.vue'

export default defineComponent({
  name: 'AttrsTable',
  components: { CodeOrText, KeyValueFilterLink },

  props: {
    dateRange: {
      type: Object as PropType<UseDateRange>,
      required: true,
    },
    system: {
      type: String,
      default: undefined,
    },
    groupId: {
      type: String,
      default: undefined,
    },
    attrs: {
      type: Object as PropType<AttrMap>,
      required: true,
    },
  },

  setup(props) {
    const attrKeys = computed((): string[] => {
      const keys = Object.keys(props.attrs)
      keys.sort()
      return keys
    })

    const isEvent = computed((): boolean => {
      return isEventSystem(props.system)
    })

    return {
      AttrKey,
      attrKeys,
      isEvent,
    }
  },
})
