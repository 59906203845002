
import { defineComponent, PropType } from 'vue'

// Components
import UnitPicker from '@/components/UnitPicker.vue'

// Utilitiers
import { MetricColumn } from '@/metrics/types'

export default defineComponent({
  name: 'MetricColumnChip',
  components: { UnitPicker },

  props: {
    name: {
      type: String,
      required: true,
    },
    column: {
      type: Object as PropType<MetricColumn>,
      required: true,
    },
  },
})
