
import {
  defineComponent,
  shallowRef,
  ref,
  computed,
  watch,
  onMounted,
  onBeforeUnmount,
  nextTick,
  PropType,
} from 'vue'

import 'gridstack/dist/gridstack.min.css'
import 'gridstack/dist/gridstack-extra.min.css'
import { GridStack, GridStackOptions } from 'gridstack'

// Composables
import { UseDateRange } from '@/use/date-range'
import { useTitle } from '@vueuse/core'
import { useUql } from '@/use/uql'
import { useDashManager, useGridColumnManager } from '@/metrics/use-dashboards'
import { useDashGauges } from '@/metrics/gauge/use-dash-gauges'
import { TableItem } from '@/metrics/use-query'

// Components
import DashQueryBuilder from '@/metrics/query/DashQueryBuilder.vue'
import DashGridColumn from '@/metrics/DashGridColumn.vue'
import DashGaugeRow from '@/metrics/gauge/DashGaugeRow.vue'
import DashGridColumnNewMenu from '@/metrics/DashGridColumnNewMenu.vue'
import DashGridColumnChartForm from '@/metrics/DashGridColumnChartForm.vue'
import DashGridColumnTableForm from '@/metrics/DashGridColumnTableForm.vue'
import DashGridColumnHeatmapForm from '@/metrics/DashGridColumnHeatmapForm.vue'

// Utilities
import { Dashboard, GridColumn, GridColumnType, DashKind } from '@/metrics/types'
import { quote } from '@/util/string'

export default defineComponent({
  name: 'DashGrid',
  components: {
    DashQueryBuilder,
    DashGridColumn,
    DashGaugeRow,
    DashGridColumnNewMenu,
    DashGridColumnChartForm,
    DashGridColumnTableForm,
    DashGridColumnHeatmapForm,
  },

  props: {
    dateRange: {
      type: Object as PropType<UseDateRange>,
      required: true,
    },
    dashboard: {
      type: Object as PropType<Dashboard>,
      required: true,
    },
    grid: {
      type: Array as PropType<GridColumn[]>,
      required: true,
    },
    gridQuery: {
      type: String,
      default: '',
    },
    groupingColumns: {
      type: Array as PropType<string[]>,
      default: undefined,
    },
    tableItem: {
      type: Object as PropType<TableItem>,
      default: undefined,
    },
    editable: {
      type: Boolean,
      default: false,
    },
  },

  setup(props, ctx) {
    useTitle(computed(() => `${props.dashboard.name} | Metrics`))

    const uql = useUql()
    watch(
      () => props.gridQuery,
      (query) => {
        uql.query = query
      },
      { immediate: true },
    )

    const dashMan = useDashManager()
    const isGridQueryDirty = computed(() => {
      return uql.query !== props.gridQuery
    })
    function saveGridQuery() {
      dashMan.update({ gridQuery: uql.query }).then(() => {
        ctx.emit('change')
      })
    }

    const internalGrid = ref<GridColumn[]>([])
    watch(
      () => props.grid,
      (grid) => {
        internalGrid.value = grid
      },
      { immediate: true },
    )

    const gridCellHeight = 20
    const gridAutoPosition = computed(() => {
      return props.grid.every((col) => col.xAxis === 0 && col.yAxis === 0)
    })
    const gridStackEl = shallowRef()
    let gridStack: GridStack | undefined

    onMounted(() => {
      watch(
        () => props.grid,
        (grid) => {
          if (gridStack) {
            gridStack.destroy(false)
          }
          if (!grid.length) {
            return
          }
          nextTick(() => {
            gridStack = initGridStack()
          })
        },
        { immediate: true },
      )
    })
    onBeforeUnmount(() => {
      if (gridStack) {
        gridStack.destroy(false)
        gridStack = undefined
      }
    })

    function initGridStack() {
      const options: GridStackOptions = {
        oneColumnSize: 1000,
        cellHeight: gridCellHeight,
        margin: 5,
        minRow: 5,
        draggable: {
          handle: '.drag-handle',
        },
        resizable: { handles: 'se,sw' },
        //float: true,
      }

      const gridStack = GridStack.init(options, gridStackEl.value)

      gridStack.on('dragstop', updateGridPos)
      gridStack.on('resizestop', updateGridPos)

      return gridStack
    }

    function updateGridPos() {
      if (!gridStack || gridStack.getColumn() <= 1) {
        return
      }

      const data = []

      const items = gridStack.getGridItems()
      for (let el of items) {
        const node = el.gridstackNode
        if (!node) {
          continue
        }

        const id = typeof node.id === 'string' ? parseInt(node.id, 10) : node.id
        if (!id) {
          continue
        }

        data.push({
          id,
          width: node.w || 0,
          height: node.h || 0,
          xAxis: node.x || 0,
          yAxis: node.y || 0,
        })

        const cell = internalGrid.value.find((cell) => cell.id === id)
        if (cell) {
          cell.width = node.w || cell.width
          cell.height = node.h || cell.height
        }
      }

      gridColumnMan.updateOrder(data)
    }

    const internalDialog = shallowRef(false)
    const activeGridColumn = ref<GridColumn>()
    const dialog = computed({
      get(): boolean {
        return Boolean(internalDialog.value && activeGridColumn.value)
      },
      set(dialog: boolean) {
        internalDialog.value = dialog
      },
    })

    const gridColumnMan = useGridColumnManager()

    const metricNames = computed((): string[] => {
      const names: string[] = []
      for (let gridCol of props.grid) {
        switch (gridCol.type) {
          case GridColumnType.Chart:
          case GridColumnType.Table:
            for (let m of gridCol.params.metrics) {
              names.push(m.name)
            }
            break
          case GridColumnType.Heatmap:
            names.push(gridCol.params.metric)
            break
        }
      }
      return names
    })

    const dashGauges = useDashGauges(() => {
      return {
        dash_kind: DashKind.Grid,
      }
    })

    watch(dialog, (dialog) => {
      if (!dialog) {
        activeGridColumn.value = undefined
      }
    })

    function gridQueryFor(gridColumn: GridColumn) {
      if (props.groupingColumns && props.tableItem && gridColumn.gridQueryTemplate) {
        let gridQuery = gridColumn.gridQueryTemplate
        for (let colName of props.groupingColumns) {
          const varName = '${' + colName + '}'
          const varValue = quote(props.tableItem[colName])
          gridQuery = gridQuery.replaceAll(varName, String(varValue))
        }
        return gridQuery
      }
      return uql.query
    }

    return {
      DashKind,
      GridColumnType,

      uql,

      internalGrid,
      gridAutoPosition,
      gridCellHeight,
      gridStackEl,

      dashMan,
      isGridQueryDirty,
      saveGridQuery,

      gridColumnMan,
      activeGridColumn,
      dialog,
      gridQueryFor,

      metricNames,

      dashGauges,
    }
  },
})
