import { render, staticRenderFns } from "./AttrFilterMenu.vue?vue&type=template&id=64ff7f79&scoped=true&"
import script from "./AttrFilterMenu.vue?vue&type=script&lang=ts&"
export * from "./AttrFilterMenu.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../node_modules/.pnpm/vue-loader@15.10.1_dbdd70d792ef5b81db31ca75740cc896/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "64ff7f79",
  null
  
)

export default component.exports