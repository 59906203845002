
import { defineComponent, shallowRef, PropType } from 'vue'

// Composables
import { useTitle } from '@vueuse/core'
import { useRoute } from '@/use/router'
import { useDataSource } from '@/use/datasource'
import { useForceReload } from '@/use/force-reload'
import { UseDateRange } from '@/use/date-range'
import { useMetricStats, MetricStats } from '@/metrics/use-metrics'

// Components
import DateRangePicker from '@/components/date/DateRangePicker.vue'
import MetricsTable from '@/metrics/MetricsTable.vue'
import ExploreMetric from '@/metrics/ExploreMetric.vue'

export default defineComponent({
  name: 'Explore',
  components: { DateRangePicker, MetricsTable, ExploreMetric },

  props: {
    dateRange: {
      type: Object as PropType<UseDateRange>,
      required: true,
    },
  },

  setup(props) {
    useTitle('Explore Metrics')

    const route = useRoute()
    const { forceReloadParams } = useForceReload()

    const activeAttrKeys = shallowRef<string[]>([])
    const attrKeysDs = useDataSource(() => {
      const { projectId } = route.value.params
      return {
        url: `/api/v1/metrics/${projectId}/attr-keys`,
        params: {
          ...forceReloadParams.value,
        },
      }
    })

    const metricStats = useMetricStats(() => {
      return {
        ...props.dateRange.axiosParams(),
        attr_key: activeAttrKeys.value,
      }
    })

    const dialog = shallowRef(false)
    const activeMetric = shallowRef<MetricStats>()

    return {
      activeAttrKeys,
      attrKeysDs,
      metricStats,

      dialog,
      activeMetric,
    }
  },
})
