
import { defineComponent, ref, PropType } from 'vue'

// Composables
import { UseTrace } from '@/tracing/use-trace'
import { UseDateRange } from '@/use/date-range'

// Components
import TraceTimeline from '@/tracing/TraceTimeline.vue'
import EventPanels from '@/tracing/EventPanels.vue'

export default defineComponent({
  name: 'TraceTabs',
  components: {
    TraceTimeline,
    EventPanels,
  },

  props: {
    dateRange: {
      type: Object as PropType<UseDateRange>,
      required: true,
    },
    trace: {
      type: Object as PropType<UseTrace>,
      default: undefined,
    },
  },

  setup() {
    const activeTab = ref()

    return {
      activeTab,
    }
  },
})
