
import { defineComponent, computed } from 'vue'

// Composables
import { useRoute, useRouteQuery } from '@/use/router'
import { useForceReload } from '@/use/force-reload'
import { useDateRange } from '@/use/date-range'
import { useUser } from '@/org/use-users'

// Components
import UptraceLogoSmall from '@/components/UptraceLogoSmall.vue'
import ProjectPicker from '@/components/ProjectPicker.vue'
import Search from '@/components/Search.vue'
import GlobalSnackbar from '@/components/GlobalSnackbar.vue'
import GlobalConfirm from '@/components/GlobalConfirm.vue'

// Utilities
import { SystemName } from '@/models/otel'

export default defineComponent({
  name: 'App',
  components: {
    UptraceLogoSmall,
    ProjectPicker,
    Search,
    GlobalSnackbar,
    GlobalConfirm,
  },

  setup() {
    useRouteQuery()
    useForceReload()
    const dateRange = useDateRange()

    const route = useRoute()
    const user = useUser()

    const helpRoute = computed(() => {
      if (route.value.name && route.value.name.startsWith('Metrics')) {
        return {
          name: 'MetricsHelp',
        }
      }
      return {
        name: 'TracingHelp',
        params: { projectId: route.value.params.projectId },
      }
    })

    return {
      SystemName,
      dateRange,
      user,
      helpRoute,
    }
  },
})
