
import { defineComponent, shallowRef, computed, PropType } from 'vue'

// Composables
import { AxiosParams } from '@/use/axios'
import { UseSystems } from '@/tracing/system/use-systems'
import { UseUql } from '@/use/uql'

// Components
import SearchFilterMenu from '@/tracing/query/SearchFilterMenu.vue'
import DurationFilterMenu from '@/tracing/query/DurationFilterMenu.vue'
import AttrFilterMenu from '@/tracing/query/AttrFilterMenu.vue'
import WhereFilterMenu from '@/tracing/query/WhereFilterMenu.vue'
import AggByMenu from '@/tracing/query/AggByMenu.vue'
import GroupByMenu from '@/tracing/query/GroupByMenu.vue'
import SpanQueryHelpDialog from '@/tracing/query/SpanQueryHelpDialog.vue'
import FacetList from '@/components/facet/FacetList.vue'

// Utilities
import { AttrKey } from '@/models/otel'

export default defineComponent({
  name: 'SpanQueryBuilder',
  components: {
    SearchFilterMenu,
    DurationFilterMenu,
    AttrFilterMenu,
    WhereFilterMenu,
    AggByMenu,
    GroupByMenu,
    SpanQueryHelpDialog,
    FacetList,
  },

  props: {
    uql: {
      type: Object as PropType<UseUql>,
      required: true,
    },
    systems: {
      type: Object as PropType<UseSystems>,
      required: true,
    },
    axiosParams: {
      type: Object as PropType<AxiosParams>,
      required: true,
    },
    aggDisabled: {
      type: Boolean,
      default: false,
    },
  },

  setup(props) {
    const drawer = shallowRef(false)

    const facetParams = computed(() => {
      if (!drawer.value) {
        return null
      }
      return {
        ...props.axiosParams,
        query: props.uql.whereQuery,
      }
    })

    function onClickOutside() {
      drawer.value = false
    }

    function closeConditional() {
      return drawer.value
    }

    return { AttrKey, drawer, facetParams, onClickOutside, closeConditional }
  },
})
