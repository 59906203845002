
import { defineComponent, computed, PropType } from 'vue'

// Composables
import { Item } from '@/use/datasource'
import { UseUql } from '@/use/uql'
import { ColumnInfo } from '@/metrics/types'

export default defineComponent({
  name: 'DashGroupingToggle',

  props: {
    attrKeys: {
      type: Array as PropType<Item[]>,
      required: true,
    },
    uql: {
      type: Object as PropType<UseUql>,
      required: true,
    },
    columns: {
      type: Array as PropType<ColumnInfo[]>,
      required: true,
    },
  },

  setup(props) {
    const grouping = computed({
      get() {
        const grouping = props.columns.filter((col) => col.isGroup)
        switch (grouping.length) {
          case 0:
            return null
          case 1:
            return grouping[0].name
          default:
            return undefined
        }
      },
      set(grouping) {
        if (grouping === undefined) {
          return
        }

        const editor = props.uql.createEditor()

        if (grouping) {
          editor.resetGroupBy(grouping)
        } else {
          editor.resetGroupBy()
        }

        props.uql.commitEdits(editor)
      },
    })

    return { grouping }
  },
})
