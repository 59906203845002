
import { defineComponent } from 'vue'

// Composables
import { useForceReload } from '@/use/force-reload'

export default defineComponent({
  name: 'ForceReloadBtn',

  setup() {
    const { forceReload } = useForceReload()

    return { forceReload }
  },
})
