
import { defineComponent, shallowRef, computed, watch, PropType } from 'vue'

// Composables
import { UseDateRange } from '@/use/date-range'
import { useUql } from '@/use/uql'
import { useActiveMetrics } from '@/metrics/use-metrics'
import { useDashManager } from '@/metrics/use-dashboards'
import { useTableQuery } from '@/metrics/use-query'

// Components
import MetricsPicker from '@/metrics/MetricsPicker.vue'
import MetricsQueryBuilder from '@/metrics/query/MetricsQueryBuilder.vue'
import TimeseriesTable from '@/metrics/TimeseriesTable.vue'
import MetricColumnChip from '@/metrics/MetricColumnChip.vue'

// Utilities
import { updateColumnMap, Dashboard, MetricColumn } from '@/metrics/types'
import { eChart as colorScheme } from '@/util/colorscheme'

export default defineComponent({
  name: 'DashTableForm',
  components: {
    MetricsPicker,
    MetricsQueryBuilder,
    TimeseriesTable,
    MetricColumnChip,
  },

  props: {
    dateRange: {
      type: Object as PropType<UseDateRange>,
      required: true,
    },
    dashboard: {
      type: Object as PropType<Dashboard>,
      required: true,
    },
    editable: {
      type: Boolean,
      default: false,
    },
  },

  setup(props, ctx) {
    const uql = useUql()

    const isValid = shallowRef(false)
    const dashMan = useDashManager()

    const activeMetrics = useActiveMetrics(computed(() => props.dashboard.tableMetrics))

    const tableQuery = useTableQuery(
      () => {
        if (!props.dashboard.tableQuery || !props.dashboard.tableMetrics.length) {
          return undefined
        }

        return {
          ...props.dateRange.axiosParams(),
          metric: props.dashboard.tableMetrics.map((m) => m.name),
          alias: props.dashboard.tableMetrics.map((m) => m.alias),
          query: props.dashboard.tableQuery,
        }
      },
      computed(() => props.dashboard.tableColumnMap),
    )

    watch(
      () => tableQuery.columns,
      (columns) => {
        updateColumnMap(props.dashboard.tableColumnMap, columns)
        assignColors(props.dashboard.tableColumnMap)
      },
    )

    watch(
      () => props.dashboard.tableQuery,
      (query) => {
        uql.query = query
      },
      { immediate: true },
    )

    watch(
      () => uql.query,
      (query) => {
        props.dashboard.tableQuery = query
      },
    )

    watch(
      () => tableQuery.query,
      (query) => {
        if (query) {
          uql.setQueryInfo(query)
        }
      },
      { immediate: true },
    )

    function submit() {
      dashMan
        .updateTable({
          tableMetrics: props.dashboard.tableMetrics,
          tableQuery: props.dashboard.tableQuery,
          tableColumnMap: props.dashboard.tableColumnMap,
        })
        .then((dash) => {
          ctx.emit('click:save', dash)
        })
    }

    return {
      uql,

      isValid,
      dashMan,
      submit,

      activeMetrics,
      tableQuery,
    }
  },
})

function assignColors(colMap: Record<string, MetricColumn>) {
  const colors = new Set(colorScheme)

  for (let colName in colMap) {
    const col = colMap[colName]
    if (!col.color) {
      continue
    }
    colors.delete(col.color)
  }

  const values = colors.values()
  for (let colName in colMap) {
    const col = colMap[colName]
    if (col.color) {
      continue
    }

    const first = values.next()
    col.color = first.value
  }
}
