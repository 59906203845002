
import { defineComponent, shallowRef, nextTick, proxyRefs, PropType } from 'vue'

// Composables
import { useRoute, useRouteQuery } from '@/use/router'
import { UsePager } from '@/use/pager'
import { UseOrder } from '@/use/order'

// Components
import ThOrder from '@/components/ThOrder.vue'
import SpanCardDateRange from '@/tracing/SpanCardDateRange.vue'
import SpanChips from '@/tracing/SpanChips.vue'

// Utilities
import { AttrKey } from '@/models/otel'
import { eventOrSpanName, Span } from '@/models/span'

export default defineComponent({
  name: 'SpansTable',
  components: {
    ThOrder,
    SpanChips,
    SpanCardDateRange,
  },

  props: {
    eventsMode: {
      type: Boolean,
      required: true,
    },
    loading: {
      type: Boolean,
      required: true,
    },
    spans: {
      type: Array as PropType<Span[]>,
      required: true,
    },
    pager: {
      type: Object as PropType<UsePager>,
      required: true,
    },
    order: {
      type: Object as PropType<UseOrder>,
      required: true,
    },
    columns: {
      type: Array as PropType<string[]>,
      default: () => [],
    },
    showSystem: {
      type: Boolean,
      default: false,
    },
  },

  setup(props) {
    const route = useRoute()
    const dialog = useDialog()

    useRouteQuery().onRouteChanged(() => {
      dialog.close()
    })

    function systemRoute(span: Span) {
      return {
        query: {
          ...route.value.query,
          system: span.system,
        },
      }
    }

    function onSortBy() {
      nextTick(() => {
        props.order.desc = true
      })
    }

    return {
      AttrKey,
      dialog,

      eventOrSpanName,
      systemRoute,
      onSortBy,
    }
  },
})

function useDialog() {
  const dialog = shallowRef(false)
  const activeSpan = shallowRef<Span>()

  function showSpan(span: Span) {
    activeSpan.value = span
    dialog.value = true
  }

  function close() {
    dialog.value = false
  }

  return proxyRefs({
    isActive: dialog,
    activeSpan,

    showSpan,
    close,
  })
}
