
import { defineComponent, computed } from 'vue'

// Composables
import { useTitle } from '@vueuse/core'
import { useProject } from '@/org/use-projects'

export default defineComponent({
  name: 'ProjectSettings',

  props: {
    disabled: {
      type: Boolean,
      default: true,
    },
  },

  setup() {
    const project = useProject()

    const title = computed((): string => {
      return project.data?.name ?? 'Project'
    })
    useTitle(title)

    const headers = [
      { text: 'Transport', value: 'transport', sortable: false },
      { text: 'DSN', value: 'dsn', sortable: false },
    ]

    const tokens = computed(() => {
      if (!project.data) {
        return []
      }
      return [
        { transport: 'OTLP/HTTP', dsn: project.http.dsn },
        { transport: 'OTLP/gRPC', dsn: project.grpc.dsn },
      ]
    })

    return {
      project,

      headers,
      tokens,
    }
  },
})
