
import { cloneDeep } from 'lodash-es'
import { defineComponent, shallowRef, computed, PropType } from 'vue'

// Composables
import { UseDateRange } from '@/use/date-range'
import { useGridColumnManager } from '@/metrics/use-dashboards'

// Components
import DashGridColumnItem from '@/metrics/DashGridColumnItem.vue'

// Utilities
import { GridColumn, GridColumnType } from '@/metrics/types'

export default defineComponent({
  name: 'DashGridColumn',
  components: {
    DashGridColumnItem,
  },

  props: {
    dateRange: {
      type: Object as PropType<UseDateRange>,
      required: true,
    },
    gridColumn: {
      type: Object as PropType<GridColumn>,
      required: true,
    },
    gridQuery: {
      type: String,
      required: true,
    },
    height: {
      type: Number,
      required: true,
    },
    editable: {
      type: Boolean,
      default: false,
    },
  },

  setup(props, ctx) {
    const menu = shallowRef(false)
    const dialog = shallowRef(false)

    const columnError = shallowRef(false)
    const metricMonitorNewRoute = computed(() => {
      switch (props.gridColumn.type) {
        case GridColumnType.Chart:
        case GridColumnType.Table:
          return {
            name: 'MonitorMetricNew',
            query: {
              name: props.gridColumn.name,
              metric: props.gridColumn.params.metrics.map((m) => m.name),
              alias: props.gridColumn.params.metrics.map((m) => m.alias),
              query: props.gridColumn.params.query,
              columns: JSON.stringify(props.gridColumn.params.columnMap),
            },
          }
        default:
          return undefined
      }
    })

    const gridColumnMan = useGridColumnManager()

    const internalGridColumn = computed(() => {
      const gridColumn = cloneDeep(props.gridColumn)
      if (props.gridQuery) {
        gridColumn.params.query += ` | ${props.gridQuery}`
      }
      return gridColumn
    })

    function del() {
      gridColumnMan.del(props.gridColumn).then(() => {
        ctx.emit('change')
      })
    }

    return {
      GridColumnType,

      menu,
      dialog,

      columnError,
      metricMonitorNewRoute,

      gridColumnMan,
      internalGridColumn,
      del,
    }
  },
})
