
import { defineComponent, shallowRef, watch, PropType } from 'vue'

// Composables
import { useDashManager } from '@/metrics/use-dashboards'

// Utilities
import { Dashboard } from '@/metrics/types'
import { requiredRule } from '@/util/validation'

export default defineComponent({
  name: 'DashEditForm',

  props: {
    dashboard: {
      type: Object as PropType<Dashboard>,
      required: true,
    },
  },

  setup(props, ctx) {
    const name = shallowRef('')
    const isValid = shallowRef(false)
    const rules = {
      name: [requiredRule],
    }

    const dashMan = useDashManager()

    watch(
      () => props.dashboard.name,
      (dashName) => {
        name.value = dashName
      },
      { immediate: true },
    )

    function update() {
      if (!isValid.value) {
        return
      }

      dashMan.update({ name: name.value }).then((dash) => {
        ctx.emit('update', dash)
      })
    }

    return {
      name,
      isValid,
      rules,

      dashMan,
      update,
    }
  },
})
