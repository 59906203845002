
import { truncate } from 'lodash-es'
import { defineComponent, computed } from 'vue'

// Composables
import { useTitle } from '@vueuse/core'
import { useRoute } from '@/use/router'
import { useDateRange } from '@/use/date-range'
import { useProject } from '@/org/use-projects'
import { useAlert, useAlertManager, AlertType, AlertState } from '@/alerting/use-alerts'

// Components
import FixedDateRangePicker from '@/components/date/FixedDateRangePicker.vue'
import AlertCardSpan from '@/alerting/AlertCardSpan.vue'
import AlertCardMetric from '@/alerting/AlertCardMetric.vue'

// Utilities
import { AttrKey } from '@/models/otel'

export default defineComponent({
  name: 'AlertCard',
  components: { FixedDateRangePicker, AlertCardSpan, AlertCardMetric },

  props: {
    alertId: {
      type: String,
      required: true,
    },
    fluid: {
      type: Boolean,
      default: false,
    },
  },

  setup(props, ctx) {
    const route = useRoute()
    const dateRange = useDateRange()
    const project = useProject()

    const alert = useAlert(() => {
      const { projectId } = route.value.params
      return {
        url: `/api/v1/projects/${projectId}/alerts/${props.alertId}`,
      }
    })
    const alertMan = useAlertManager()

    useTitle(
      computed(() => {
        return alert.data?.name ?? 'Alert'
      }),
    )

    const breadcrumbs = computed(() => {
      const bs: any[] = []

      bs.push({
        text: project.data?.name ?? 'Project',
        to: {
          name: 'ProjectShow',
        },
      })

      bs.push({
        text: 'Alerts',
        to: {
          name: 'AlertList',
        },
        exact: true,
      })

      if (!alert.data) {
        bs.push({ text: 'Alert' })
        return bs
      }

      bs.push({
        text: truncate(alert.data.name, { length: 60 }),
        to: {
          name: 'AlertShow',
          params: { alertId: alert.data.id },
        },
      })

      return bs
    })

    function toggleAlert() {
      if (!alert.data) {
        return
      }
      alertMan.toggle(alert.data).then(() => {
        alert.reload()
        ctx.emit('change', alert)
      })
    }

    return {
      AlertType,
      AlertState,
      AttrKey,

      dateRange,
      breadcrumbs,

      alert,
      alertMan,

      toggleAlert,
    }
  },
})
