
import { defineComponent, computed, PropType } from 'vue'

// Utilities
import { MetricStats } from '@/metrics/use-metrics'

export default defineComponent({
  name: 'MetricsTable',

  props: {
    loading: {
      type: Boolean,
      required: true,
    },
    metrics: {
      type: Array as PropType<MetricStats[]>,
      required: true,
    },
  },

  setup() {
    const headers = computed(() => {
      const headers = []
      headers.push({ text: 'Metric', value: 'name', sortable: true, align: 'start' })
      headers.push({ text: 'Instrument', value: 'instrument', sortable: true, align: 'start' })
      headers.push({ text: 'Timeseries', value: 'numTimeseries', sortable: true, align: 'end' })
      return headers
    })

    return { headers }
  },
})
