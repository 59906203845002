
import { omit } from 'lodash-es'
import { defineComponent, computed, PropType } from 'vue'

// Composables
import { useRouter } from '@/use/router'
import { UseDateRange } from '@/use/date-range'
import { createUqlEditor } from '@/use/uql'
import { useGroups } from '@/tracing/use-explore-spans'

// Components
import GroupsList from '@/tracing/GroupsList.vue'

// Utilities
import { SystemName, AttrKey } from '@/models/otel'

export default defineComponent({
  name: 'OverviewAttr',
  components: { GroupsList },

  props: {
    dateRange: {
      type: Object as PropType<UseDateRange>,
      required: true,
    },
    axiosParams: {
      type: Object,
      required: true,
    },
  },

  setup(props) {
    const { route } = useRouter()

    const attr = computed(() => {
      return route.value.params.attr ?? AttrKey.spanSystem
    })

    const query = computed(() => {
      return createUqlEditor()
        .exploreAttr(attr.value)
        .add(`max(${AttrKey.spanDuration})`)
        .toString()
    })

    const internalAxiosParams = computed(() => {
      const ss = [query.value, route.value.query.query]
      return {
        ...props.axiosParams,
        query: ss.join(' | '),
      }
    })

    const groups = useGroups(() => {
      const { projectId } = route.value.params
      return {
        url: `/api/v1/tracing/${projectId}/groups`,
        params: internalAxiosParams.value,
      }
    })

    const plottedColumns = computed(() => {
      return groups.plottableColumns
        .map((col) => col.name)
        .filter((colName) => colName !== `max(${AttrKey.spanDuration})`)
    })

    const groupListRoute = computed(() => {
      return {
        name: 'SpanGroupList',
        query: {
          ...omit(route.value.query, 'columns'),
          query: query.value,
        },
      }
    })

    const spanListRoute = computed(() => {
      return {
        name: 'SpanList',
        query: {
          ...omit(route.value.query, 'sort_by', 'sort_desc'),
          query: query.value,
        },
      }
    })

    const metric = computed((): string => {
      switch (attr.value) {
        case AttrKey.serviceName:
          return 'uptrace.tracing.services'
        case AttrKey.hostName:
          return 'uptrace.tracing.hosts'
        default:
          return ''
      }
    })

    return {
      SystemName,
      AttrKey,

      attr,
      internalAxiosParams,
      groups,
      plottedColumns,
      groupListRoute,
      spanListRoute,
      metric,
    }
  },
})
