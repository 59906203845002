
import { defineComponent, PropType } from 'vue'

// Composables
import { Alert } from '@/alerting/use-alerts'

// Utilities
import { AttrKey, SystemName } from '@/models/otel'

export default defineComponent({
  name: 'AlertChips',

  props: {
    alert: {
      type: Object as PropType<Alert>,
      required: true,
    },
  },

  setup(props) {
    return { SystemName, AttrKey }
  },
})
