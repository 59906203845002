
import { filter as fuzzyFilter } from 'fuzzaldrin-plus'
import { defineComponent, shallowRef, computed, watch, PropType } from 'vue'

// Composables
import { useRouter } from '@/use/router'
import { UseDateRange } from '@/use/date-range'
import { UseSystems } from '@/tracing/system/use-systems'
import { UseUql } from '@/use/uql'
import { useGroups, Group } from '@/tracing/use-explore-spans'

// Components
import GroupsList from '@/tracing/GroupsList.vue'

import { isDummySystem } from '@/models/otel'

export default defineComponent({
  name: 'TracingGroups',
  components: { GroupsList },

  props: {
    dateRange: {
      type: Object as PropType<UseDateRange>,
      required: true,
    },
    systems: {
      type: Object as PropType<UseSystems>,
      required: true,
    },
    uql: {
      type: Object as PropType<UseUql>,
      required: true,
    },
    eventsMode: {
      type: Boolean,
      required: true,
    },
    axiosParams: {
      type: Object as PropType<Record<string, any>>,
      required: true,
    },
  },

  setup(props) {
    const { route } = useRouter()

    const groups = useGroups(() => {
      const { projectId } = route.value.params
      return {
        url: `/api/v1/tracing/${projectId}/groups`,
        params: props.axiosParams,
      }
    })

    const searchInput = shallowRef('')
    const numGroup = shallowRef(0)
    const filteredGroups = computed((): Group[] => {
      if (!searchInput.value) {
        return groups.items
      }
      return fuzzyFilter(groups.items, searchInput.value, { key: '_name' })
    })

    const internalAxiosParams = computed(() => {
      if (!groups.status.isResolved()) {
        // Block requests until items are ready.
        return { _: undefined }
      }
      return props.axiosParams
    })

    const showSystem = computed(() => {
      if (route.value.params.eventSystem) {
        return false
      }

      const systems = props.systems.activeSystem
      if (systems.length > 1) {
        return true
      }
      if (systems.length === 1) {
        return isDummySystem(systems[0])
      }
      return false
    })

    watch(
      () => groups.queryInfo,
      (queryInfo) => {
        if (queryInfo) {
          props.uql.setQueryInfo(queryInfo)
        }
      },
    )

    return {
      internalAxiosParams,
      groups,

      searchInput,
      numGroup,
      filteredGroups,
      showSystem,
    }
  },
})
