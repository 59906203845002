
import { defineComponent, shallowRef, computed, PropType } from 'vue'

// Components
import MetricChart from '@/metrics/MetricChart.vue'
import ChartLegendTable from '@/metrics/ChartLegendTable.vue'
import ChartLegendList from '@/metrics/ChartLegendList.vue'

// Utilities
import { EventBus } from '@/models/eventbus'
import {
  ChartKind,
  ChartLegend,
  LegendType,
  LegendPlacement,
  StyledTimeseries,
} from '@/metrics/types'

export default defineComponent({
  name: 'GridColumnChart',
  components: {
    ChartLegendTable,
    ChartLegendList,
    MetricChart,
  },

  props: {
    loading: {
      type: Boolean,
      required: true,
    },
    resolved: {
      type: Boolean,
      required: true,
    },
    timeseries: {
      type: Array as PropType<StyledTimeseries[]>,
      required: true,
    },
    time: {
      type: Array as PropType<string[]>,
      required: true,
    },
    chartKind: {
      type: String as PropType<ChartKind>,
      default: ChartKind.Line,
    },
    legend: {
      type: Object as PropType<ChartLegend>,
      required: true,
    },
    height: {
      type: Number,
      default: 200,
    },
  },

  setup(props, ctx) {
    const eventBus = new EventBus()

    const legendHeight = shallowRef(0)
    function onLegendResize(event: any) {
      legendHeight.value = event.detail.height
    }

    const showDuplicateLegend = computed(() => {
      if (
        props.legend.placement === LegendPlacement.Bottom &&
        props.legend.type === LegendType.Table
      ) {
        return false
      }
      return 'expanded-item' in ctx.slots
    })

    const chartHeight = computed(() => {
      const minHeight = 140

      if (
        props.legend.type === LegendType.None ||
        props.legend.placement === LegendPlacement.Right
      ) {
        return props.height
      }

      switch (props.legend.type) {
        case LegendType.Table: {
          const height = props.height - legendHeight.value
          return Math.max(height, minHeight)
        }
        case LegendType.List: {
          const height = props.height - legendHeight.value
          return Math.max(height, minHeight)
        }
        default:
          console.error(`unsupported legend type: ${props.legend.type}`)
          return props.height
      }
    })

    const currentTimeseries = shallowRef<StyledTimeseries[]>()
    const activeTimeseries = computed(() => {
      if (props.legend.type !== LegendType.None && currentTimeseries.value) {
        return currentTimeseries.value
      }
      return props.timeseries
    })

    return {
      LegendType,
      LegendPlacement,

      showDuplicateLegend,
      chartHeight,

      eventBus,
      currentTimeseries,
      activeTimeseries,

      onLegendResize,
    }
  },
})
