
import { defineComponent, PropType } from 'vue'

// Composables
import { useDashManager } from '@/metrics/use-dashboards'

// Utilities
import { Dashboard } from '@/metrics/types'

export default defineComponent({
  name: 'DashPinBtn',

  props: {
    dashboard: {
      type: Object as PropType<Dashboard>,
      required: true,
    },
  },

  setup(props, ctx) {
    const dashMan = useDashManager()

    function pinDashboard() {
      dashMan.pin(props.dashboard).then((dash) => {
        ctx.emit('update', dash)
      })
    }

    function unpinDashboard() {
      dashMan.unpin(props.dashboard).then((dash) => {
        ctx.emit('update', dash)
      })
    }

    return { dashMan, pinDashboard, unpinDashboard }
  },
})
