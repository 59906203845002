
import { cloneDeep } from 'lodash-es'
import { defineComponent, shallowRef, reactive, computed, proxyRefs, watch, PropType } from 'vue'

// Composables
import { UseDateRange } from '@/use/date-range'
import { useTitle } from '@vueuse/core'
import { useRoute } from '@/use/router'
import { useDataSource } from '@/use/datasource'
import { useUql } from '@/use/uql'
import { useActiveMetrics } from '@/metrics/use-metrics'
import { useTableQuery, TableItem } from '@/metrics/use-query'
import { useDashGauges } from '@/metrics/gauge/use-dash-gauges'

// Components
import MetricsQueryBuilder from '@/metrics/query/MetricsQueryBuilder.vue'
import DashGroupingToggle from '@/metrics/query/DashGroupingToggle.vue'
import TimeseriesTable from '@/metrics/TimeseriesTable.vue'
import DashTableForm from '@/metrics/DashTableForm.vue'
import DashGrid from '@/metrics/DashGrid.vue'
import DashGaugeRow from '@/metrics/gauge/DashGaugeRow.vue'

// Utilities
import { AttrKey } from '@/models/otel'
import { Dashboard, DashKind, GridColumn } from '@/metrics/types'

interface Props {
  dashboard: Dashboard
  grid: GridColumn[]
}

export default defineComponent({
  name: 'DashTable',
  components: {
    MetricsQueryBuilder,
    DashGroupingToggle,
    TimeseriesTable,
    DashTableForm,
    DashGrid,
    DashGaugeRow,
  },

  props: {
    dateRange: {
      type: Object as PropType<UseDateRange>,
      required: true,
    },
    dashboard: {
      type: Object as PropType<Dashboard>,
      required: true,
    },
    grid: {
      type: Array as PropType<GridColumn[]>,
      required: true,
    },
    editable: {
      type: Boolean,
      default: false,
    },
  },

  setup(props, ctx) {
    useTitle(computed(() => `${props.dashboard.name} | Metrics`))

    const route = useRoute()
    const dialog = shallowRef(false)
    const uql = useUql()

    const dashGauges = useDashGauges(() => {
      return {
        dash_kind: DashKind.Table,
      }
    })

    const activeMetrics = useActiveMetrics(computed(() => props.dashboard.tableMetrics))

    const attrKeysDs = useDataSource(() => {
      if (!props.dashboard.tableMetrics.length) {
        return undefined
      }

      const { projectId } = route.value.params
      return {
        url: `/api/v1/metrics/${projectId}/attr-keys`,
        params: {
          ...props.dateRange.axiosParams(),
          metric: props.dashboard.tableMetrics.map((m) => m.name),
        },
      }
    })

    const tableQuery = useTableQuery(
      () => {
        if (!props.dashboard.tableQuery || !props.dashboard.tableMetrics.length) {
          return { _: undefined }
        }

        return {
          ...props.dateRange.axiosParams(),
          metric: props.dashboard.tableMetrics.map((m) => m.name),
          alias: props.dashboard.tableMetrics.map((m) => m.alias),
          query: uql.query,
        }
      },
      computed(() => props.dashboard.tableColumnMap),
    )
    tableQuery.order.syncQueryParams()

    watch(
      () => props.dashboard.tableQuery ?? '',
      (query) => {
        uql.query = query
      },
      { immediate: true },
    )

    watch(
      () => tableQuery.query,
      (query) => {
        if (query) {
          uql.setQueryInfo(query)
        }
      },
      { immediate: true },
    )

    const tableItem = useTableItem(props)
    function gridQueryFor(tableItem: TableItem): string {
      const ss = []

      if (tableItem._query) {
        ss.push(tableItem._query)
      }

      if (props.dashboard.gridQuery) {
        ss.push(props.dashboard.gridQuery)
      }

      return ss.join(' | ')
    }

    return {
      AttrKey,
      DashKind,

      dialog,
      dashGauges,

      uql,
      activeMetrics,
      attrKeysDs,
      tableQuery,

      tableItem,
      gridQueryFor,

      cloneDeep,
      reactive,
    }
  },
})

function useTableItem(props: Props) {
  const dialog = shallowRef(false)
  const activeItem = shallowRef<TableItem>()

  const tableListeners = computed(() => {
    if (!props.grid.length) {
      return {}
    }
    return {
      click(item: TableItem) {
        activeItem.value = item
        dialog.value = true
      },
    }
  })

  return proxyRefs({
    dialog,
    active: activeItem,
    listeners: tableListeners,
  })
}
