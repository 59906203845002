
import { omit } from 'lodash-es'
import { defineComponent, shallowRef, PropType } from 'vue'

// Composables
import { useRoute } from '@/use/router'
import { useSavedViewManager, UseSavedViews } from '@/tracing/views/use-saved-views'

// Components
import SavedViewsList from '@/tracing/views/SavedViewsList.vue'

// Utilities
import { requiredRule } from '@/util/validation'

export default defineComponent({
  name: 'SaveViewDrawer',
  components: { SavedViewsList },

  props: {
    views: {
      type: Object as PropType<UseSavedViews>,
      required: true,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },

  setup(props) {
    const route = useRoute()
    const drawer = shallowRef(false)

    const form = shallowRef()
    const isValid = shallowRef(true)
    const name = shallowRef('')
    const rules = {
      name: [requiredRule],
    }
    const viewMan = useSavedViewManager()

    function saveView() {
      if (!form.value.validate()) {
        return
      }

      viewMan
        .save({
          name: name.value,
          route: route.value.name as string,
          params: route.value.params,
          query: omit(route.value.query, 'time_gte'),
        })
        .then(() => {
          name.value = ''
          form.value.reset()
          props.views.reload()
        })
    }

    function onClickOutside() {
      drawer.value = false
    }

    function closeConditional() {
      return drawer.value
    }

    return {
      form,
      isValid,
      name,
      rules,
      viewMan,
      saveView,

      drawer,
      onClickOutside,
      closeConditional,
    }
  },
})
