
import { defineComponent, computed, watch, proxyRefs } from 'vue'

// Composables
import { useTitle } from '@vueuse/core'
import { useDateRange, UseDateRange } from '@/use/date-range'
import { useTrace, UseTrace } from '@/tracing/use-trace'
import { createUqlEditor } from '@/use/uql'

// Components
import FixedDateRangePicker from '@/components/date/FixedDateRangePicker.vue'
import LoadPctileChart from '@/components/LoadPctileChart.vue'
import SpanSystemBarChart from '@/components/SpanSystemBarChart.vue'
import TraceTabs from '@/tracing/TraceTabs.vue'
import TraceError from '@/tracing/TraceError.vue'

// Utilities
import { AttrKey, SystemName } from '@/models/otel'
import { eventOrSpanName } from '@/models/span'

export default defineComponent({
  name: 'TraceShow',
  components: {
    FixedDateRangePicker,
    LoadPctileChart,
    SpanSystemBarChart,
    TraceTabs,
    TraceError,
  },

  setup() {
    useTitle('View trace')
    const dateRange = useDateRange()
    const trace = useTrace()

    const axiosParams = computed(() => {
      if (!trace.root) {
        return
      }
      return {
        ...dateRange.axiosParams(),
        system: trace.root.system,
        group_id: trace.root.groupId,
      }
    })

    const groupRoute = computed(() => {
      if (!trace.root) {
        return
      }

      return {
        name: 'SpanList',
        query: {
          ...dateRange.queryParams(),
          system: trace.root.system,
          query: createUqlEditor()
            .exploreAttr(AttrKey.spanGroupId)
            .where(AttrKey.spanGroupId, '=', trace.root.groupId)
            .toString(),
        },
      }
    })

    const exploreTraceRoute = computed(() => {
      if (!trace.root) {
        return
      }

      return {
        name: 'SpanGroupList',
        query: {
          ...dateRange.queryParams(),
          system: SystemName.spansAll,
          query: [
            `where ${AttrKey.spanTraceId} = ${trace.root.traceId}`,
            `group by ${AttrKey.spanGroupId}`,
            AttrKey.spanCount,
            AttrKey.spanErrorCount,
            `{p50,p90,p99,sum}(${AttrKey.spanDuration})`,
          ].join(' | '),
          plot: null,
        },
      }
    })

    watch(
      () => trace.root,
      (span) => {
        if (span) {
          useTitle(span.name)
        }
      },
    )

    return {
      dateRange,
      trace,
      meta: useMeta(dateRange, trace),

      axiosParams,
      groupRoute,
      exploreTraceRoute,
      eventOrSpanName,
    }
  },
})

function useMeta(dateRange: UseDateRange, trace: UseTrace) {
  const breadcrumbs = computed(() => {
    const root = trace.root
    const bs: any[] = []

    if (!root) {
      return bs
    }

    if (root.system) {
      bs.push({
        text: root.system,
        to: {
          name: 'SpanGroupList',
          query: {
            ...dateRange.queryParams(),
            system: root.system,
          },
        },
        exact: true,
      })
    }

    if (root.system && root.groupId) {
      bs.push({
        text: eventOrSpanName(root, 60),
        to: {
          name: 'SpanList',
          query: {
            ...dateRange.queryParams(),
            system: root.system,
            query: createUqlEditor()
              .exploreAttr(AttrKey.spanGroupId)
              .where(AttrKey.spanGroupId, '=', root.groupId)
              .toString(),
          },
        },
        exact: true,
      })
    }

    bs.push({
      text: trace.id,
    })

    return bs
  })

  return proxyRefs({ breadcrumbs })
}
