
import { defineComponent, computed, PropType } from 'vue'

// Composables
import { UseDateRange } from '@/use/date-range'
import { createUqlEditor } from '@/use/uql'

// Components
import FixedDateRangePicker from '@/components/date/FixedDateRangePicker.vue'
import SpanBodyCard from '@/tracing/SpanBodyCard.vue'

// Utitlies
import { eventOrSpanName, Span } from '@/models/span'
import { isEventSystem, AttrKey } from '@/models/otel'

export default defineComponent({
  name: 'SpanCard',
  components: { FixedDateRangePicker, SpanBodyCard },

  props: {
    dateRange: {
      type: Object as PropType<UseDateRange>,
      required: true,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    span: {
      type: Object as PropType<Span>,
      required: true,
    },
    fluid: {
      type: Boolean,
      default: false,
    },
  },

  setup(props) {
    const isEvent = computed((): boolean => {
      return isEventSystem(props.span.system)
    })

    const breadcrumbs = computed(() => {
      const bs: any[] = []

      bs.push({
        text: props.span.system,
        to: {
          name: isEvent.value ? 'EventGroupList' : 'SpanGroupList',
          params: { projectId: props.span.projectId },
          query: {
            ...props.dateRange.queryParams(),
            system: props.span.system,
          },
        },
        exact: true,
      })

      bs.push({
        text: eventOrSpanName(props.span, 40),
        to: {
          name: isEvent.value ? 'EventList' : 'SpanList',
          params: { projectId: props.span.projectId },
          query: {
            ...props.dateRange.queryParams(),
            system: props.span.system,
            query: createUqlEditor()
              .exploreAttr(AttrKey.spanGroupId, isEvent.value)
              .where(AttrKey.spanGroupId, '=', props.span.groupId)
              .toString(),
          },
        },
        exact: true,
      })

      if (!props.span.standalone && props.span.traceId) {
        bs.push({
          text: props.span.traceId,
          to: {
            name: 'TraceShow',
            params: {
              projectId: props.span.projectId,
              traceId: props.span.traceId,
            },
          },
          exact: true,
        })
      }

      bs.push({
        text: 'Span',
        to: {
          name: 'SpanShow',
          params: {
            projectId: props.span.projectId,
            traceId: props.span.traceId,
            spanId: props.span.id,
          },
        },
        exact: true,
      })

      return bs
    })

    return { breadcrumbs }
  },
})
