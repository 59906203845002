
import { defineComponent, shallowRef, computed, watch, PropType } from 'vue'

// Composables
import { UseDateRange } from '@/use/date-range'
import { useUql } from '@/use/uql'
import { useActiveMetrics } from '@/metrics/use-metrics'
import {
  formatGauge,
  useDashGaugeManager,
  useDashGaugeQuery,
} from '@/metrics/gauge/use-dash-gauges'

// Components
import MetricsPicker from '@/metrics/MetricsPicker.vue'
import MetricsQueryBuilder from '@/metrics/query/MetricsQueryBuilder.vue'
import MetricColumnChip from '@/metrics/MetricColumnChip.vue'

// Utilities
import { requiredRule } from '@/util/validation'
import { updateColumnMap, DashGauge } from '@/metrics/types'

export default defineComponent({
  name: 'DashGaugeForm',
  components: {
    MetricsPicker,
    MetricsQueryBuilder,
    MetricColumnChip,
  },

  props: {
    dateRange: {
      type: Object as PropType<UseDateRange>,
      required: true,
    },
    dashGauge: {
      type: Object as PropType<DashGauge>,
      required: true,
    },
    editable: {
      type: Boolean,
      default: false,
    },
  },

  setup(props, ctx) {
    const metricsPicker = shallowRef()
    const form = shallowRef()
    const isValid = shallowRef(false)
    const rules = { metrics: [requiredRule], name: [requiredRule], description: [requiredRule] }

    const uql = useUql()
    const dashGaugeMan = useDashGaugeManager()

    const activeMetrics = useActiveMetrics(computed(() => props.dashGauge.metrics))

    const gaugeQuery = useDashGaugeQuery(
      () => {
        if (!props.dashGauge || !props.dashGauge.metrics.length || !props.dashGauge.query) {
          return undefined
        }

        return {
          ...props.dateRange.axiosParams(),
          metric: props.dashGauge.metrics.map((m) => m.name),
          alias: props.dashGauge.metrics.map((m) => m.alias),
          query: props.dashGauge.query,
        }
      },
      computed(() => props.dashGauge.columnMap),
    )

    const gaugeText = computed(() => {
      return formatGauge(
        gaugeQuery.values,
        gaugeQuery.columns,
        props.dashGauge.template,
        'Select a metric first...',
      )
    })

    watch(
      () => props.dashGauge.query,
      (query) => {
        uql.query = query
      },
      { immediate: true },
    )

    watch(
      () => uql.query,
      (query) => {
        props.dashGauge.query = query
      },
    )

    watch(
      () => gaugeQuery.query,
      (query) => {
        if (query) {
          uql.setQueryInfo(query)
        }
      },
      { immediate: true },
    )

    watch(
      () => gaugeQuery.columns,
      (columns) => {
        updateColumnMap(props.dashGauge.columnMap, columns)
      },
    )

    function submit() {
      const r1 = metricsPicker.value.validate()
      const r2 = form.value.validate()
      if (!r1 || !r2) {
        return
      }

      dashGaugeMan.save(props.dashGauge).then((dashGauge) => {
        ctx.emit('click:save', dashGauge)
      })
    }

    return {
      uql,
      dashGaugeMan,

      activeMetrics,
      gaugeQuery,
      gaugeText,

      metricsPicker,
      form,
      isValid,
      rules,
      submit,
    }
  },
})
