
import { defineComponent, computed, PropType } from 'vue'
import colors from 'vuetify/lib/util/colors'

// Composables
import { formatGauge, useDashGaugeManager } from '@/metrics/gauge/use-dash-gauges'

// Utilities
import { DashGauge, StyledColumnInfo } from '@/metrics/types'

export default defineComponent({
  name: 'DashGaugeCard',

  props: {
    loading: {
      type: Boolean,
      required: true,
    },
    dashGauge: {
      type: Object as PropType<DashGauge>,
      required: true,
    },
    columns: {
      type: Array as PropType<StyledColumnInfo[]>,
      required: true,
    },
    values: {
      type: Object as PropType<Record<string, any>>,
      required: true,
    },
    showEdit: {
      type: Boolean,
      default: false,
    },
    editable: {
      type: Boolean,
      default: false,
    },
  },

  setup(props, ctx) {
    const gaugeText = computed(() => {
      return formatGauge(props.values, props.columns, props.dashGauge.template)
    })

    const color = computed(() => {
      for (let col of props.columns) {
        if (col.color) {
          return col.color
        }
      }
      return colors.blue.darken2
    })

    const style = computed(() => {
      return {
        'border-bottom-color': color.value,
      }
    })

    const dashGaugeMan = useDashGaugeManager()

    function del() {
      dashGaugeMan.del(props.dashGauge).then(() => {
        ctx.emit('change')
      })
    }

    return {
      gaugeText,
      style,

      dashGaugeMan,
      del,
    }
  },
})
