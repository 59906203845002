
import { filter as fuzzyFilter } from 'fuzzaldrin-plus'
import { defineComponent, shallowRef, computed, watch } from 'vue'

// Utilities
import { truncateMiddle } from '@/util/string'

interface Item {
  text: string
  value: any
}

export default defineComponent({
  name: 'SearchableList',

  props: {
    loading: {
      type: Boolean,
      default: false,
    },
    items: {
      type: Array,
      required: true,
    },
    numItem: {
      type: Number,
      default: undefined,
    },
    searchInput: {
      type: String,
      default: '',
    },
    returnObject: {
      type: Boolean,
      default: false,
    },
  },

  setup(props, ctx) {
    const search = shallowRef('')

    const normalizedItems = computed((): Item[] => {
      return props.items.map((item) => {
        if (typeof item === 'string') {
          return { text: item, value: item }
        }
        return item as Item
      })
    })

    const filteredItems = computed((): Item[] => {
      let items = normalizedItems.value.slice()

      if (!search.value) {
        return items
      }

      // @ts-ignore
      return fuzzyFilter(items, search.value, { key: 'text' })
    })

    const showFilter = computed(() => {
      if (props.numItem) {
        return props.numItem > 10
      }
      return props.items.length > 10
    })

    watch(
      () => props.searchInput,
      (s) => {
        search.value = s
      },
      { immediate: true },
    )

    watch(search, (search) => {
      ctx.emit('update:searchInput', search)
    })

    function onClick(item: Item) {
      if (props.returnObject) {
        ctx.emit('input', item)
      } else {
        ctx.emit('input', item.value)
      }
    }

    return { search, filteredItems, showFilter, onClick, truncateMiddle }
  },
})
