
import { defineComponent, shallowRef, PropType } from 'vue'

// Composables
import { useNotifChannelManager, SlackNotifChannel } from '@/alerting/use-notif-channels'

// Utilities
import { requiredRule } from '@/util/validation'

export default defineComponent({
  name: 'NotifChannelSlackForm',

  props: {
    channel: {
      type: Object as PropType<SlackNotifChannel>,
      required: true,
    },
  },

  setup(props, ctx) {
    const man = useNotifChannelManager()

    const form = shallowRef()
    const isValid = shallowRef(true)
    const rules = {
      name: [requiredRule],
      webhookUrl: [requiredRule],
    }

    function submit() {
      save().then(() => {
        ctx.emit('click:save')
        ctx.emit('click:close')
      })
    }

    function save() {
      if (!form.value.validate()) {
        return Promise.reject()
      }

      if (props.channel.id) {
        return man.slackUpdate(props.channel)
      }
      return man.slackCreate(props.channel)
    }

    return {
      man,

      form,
      isValid,
      rules,
      submit,
    }
  },
})
