
import { orderBy } from 'lodash-es'
import { defineComponent, shallowRef, computed, watch, watchEffect, PropType } from 'vue'

// Composables
import { useRouteQuery } from '@/use/router'
import { usePager } from '@/use/pager'
import { UseOrder } from '@/use/order'
import { UseDateRange } from '@/use/date-range'
import { UseUql } from '@/use/uql'
import { Group, ColumnInfo } from '@/tracing/use-explore-spans'

// Components
import DateRangePicker from '@/components/date/DateRangePicker.vue'
import GroupsTable from '@/tracing/GroupsTable.vue'
import GroupMetrics from '@/metrics/GroupMetrics.vue'

// Utilities
import { AttrKey } from '@/models/otel'

export default defineComponent({
  name: 'GroupsList',
  components: { DateRangePicker, GroupsTable, GroupMetrics },

  props: {
    dateRange: {
      type: Object as PropType<UseDateRange>,
      required: true,
    },
    eventsMode: {
      type: Boolean,
      default: false,
    },
    uql: {
      type: Object as PropType<UseUql>,
      default: undefined,
    },
    loading: {
      type: Boolean,
      required: true,
    },
    isResolved: {
      type: Boolean,
      required: true,
    },
    groups: {
      type: Array as PropType<Group[]>,
      required: true,
    },
    columns: {
      type: Array as PropType<ColumnInfo[]>,
      required: true,
    },
    plottableColumns: {
      type: Array as PropType<ColumnInfo[]>,
      required: true,
    },
    plottedColumns: {
      type: Array as PropType<string[]>,
      default: undefined,
    },
    showPlottedColumnItems: {
      type: Boolean,
      default: false,
    },
    order: {
      type: Object as PropType<UseOrder>,
      required: true,
    },
    showSystem: {
      type: Boolean,
      default: false,
    },
    axiosParams: {
      type: Object as PropType<Record<string, any>>,
      default: undefined,
    },
  },

  setup(props, ctx) {
    const dialog = shallowRef(false)
    const activeGroup = shallowRef<Group>()

    const systemFilter = shallowRef<string[]>([])
    const systemFilterItems = computed((): SystemFilter[] => {
      const filters = buildSystemFilters(props.groups)
      if (filters.length <= 5) {
        return filters
      }
      return buildTypeFilters(props.groups)
    })
    watch(systemFilterItems, () => {
      systemFilter.value = []
    })

    const filteredGroups = computed(() => {
      if (!systemFilter.value.length) {
        return props.groups
      }

      return props.groups.filter((group) => {
        const system = group[AttrKey.spanSystem]
        if (!system) {
          return true
        }

        for (let needle of systemFilter.value) {
          if (system.startsWith(needle)) {
            return true
          }
        }
        return false
      })
    })

    const pager = usePager({ perPage: 15 })
    const pagedGroups = computed((): Group[] => {
      const pagedGroups = filteredGroups.value.slice(pager.pos.start, pager.pos.end)
      return pagedGroups
    })
    watch(
      () => filteredGroups.value.length,
      (numItem) => {
        pager.numItem = numItem
        ctx.emit('update:num-group', numItem)
      },
    )

    const internalPlottedColumns = shallowRef<string[]>()
    watchEffect(() => {
      if (!props.plottableColumns.length) {
        internalPlottedColumns.value = undefined
        return
      }

      if (!internalPlottedColumns.value) {
        internalPlottedColumns.value =
          props.plottedColumns ?? props.plottableColumns.slice(0, 1).map((col) => col.name)
        return
      }

      internalPlottedColumns.value = internalPlottedColumns.value.filter((colName) => {
        return props.plottableColumns.findIndex((item) => item.name === colName) >= 0
      })
    })
    const plottableColumnItems = computed(() => {
      const items = props.plottableColumns.map((col) => {
        return { text: col.name, value: col.name }
      })
      return items
    })

    useRouteQuery().sync({
      fromQuery(params) {
        if (Array.isArray(params.columns)) {
          internalPlottedColumns.value = params.columns
        } else if (params.columns) {
          internalPlottedColumns.value = [params.columns]
        } else if (params.column) {
          internalPlottedColumns.value = [params.column]
        }
      },
      toQuery() {
        return {
          columns: internalPlottedColumns.value,
        }
      },
    })

    return {
      dialog,
      activeGroup,

      pagedGroups,
      pager,

      internalPlottedColumns,
      plottableColumnItems,

      systemFilter,
      systemFilterItems,
    }
  },
})

interface SystemFilter {
  system: string
  numGroup: number
}

function buildSystemFilters(groups: Group[]) {
  const systemMap: Record<string, SystemFilter> = {}

  for (let group of groups) {
    const system = group[AttrKey.spanSystem]
    if (!system) {
      continue
    }

    let item = systemMap[system]
    if (!item) {
      item = {
        system,
        numGroup: 0,
      }
      systemMap[system] = item
    }
    item.numGroup++
  }

  const filters: SystemFilter[] = []

  for (let system in systemMap) {
    filters.push(systemMap[system])
  }

  orderBy(filters, 'system')
  return filters
}

function buildTypeFilters(groups: Group[]) {
  const systemMap: Record<string, SystemFilter> = {}

  for (let group of groups) {
    let system = group[AttrKey.spanSystem]
    if (!system) {
      continue
    }

    const i = system.indexOf(':')
    if (i >= 0) {
      system = system.slice(0, i)
    }

    let item = systemMap[system]
    if (!item) {
      item = {
        system,
        numGroup: 0,
      }
      systemMap[system] = item
    }
    item.numGroup++
  }

  const filters: SystemFilter[] = []

  for (let system in systemMap) {
    filters.push(systemMap[system])
  }

  orderBy(filters, 'system')
  return filters
}
