
import { defineComponent, shallowRef, reactive, computed, PropType } from 'vue'

// Composables
import { UseDateRange } from '@/use/date-range'
import { emptyDashGauge } from '@/metrics/gauge/use-dash-gauges'

// Components
import DashGaugeRowCol from '@/metrics/gauge/DashGaugeRowCol.vue'
import DashGaugeForm from '@/metrics/gauge/DashGaugeForm.vue'

// Utilities
import { DashKind, DashGauge } from '@/metrics/types'

export default defineComponent({
  name: 'DashGaugeRow',
  components: { DashGaugeRowCol, DashGaugeForm },

  props: {
    dateRange: {
      type: Object as PropType<UseDateRange>,
      required: true,
    },
    dashKind: {
      type: String as PropType<DashKind>,
      required: true,
    },
    gridQuery: {
      type: String,
      default: '',
    },
    editable: {
      type: Boolean,
      default: false,
    },
    dashGauges: {
      type: Array as PropType<DashGauge[]>,
      required: true,
    },
  },

  setup(props) {
    const internalDialog = shallowRef(false)
    const activeDashGauge = shallowRef<DashGauge>()

    const dialog = computed({
      get(): boolean {
        return Boolean(internalDialog.value && activeDashGauge.value)
      },
      set(dialog: boolean) {
        internalDialog.value = dialog
      },
    })

    function openDialog(dashGauge: DashGauge | undefined) {
      if (!dashGauge) {
        dashGauge = emptyDashGauge(props.dashKind)
      }
      activeDashGauge.value = reactive(dashGauge)
      dialog.value = true
    }

    return {
      dialog,
      openDialog,

      activeDashGauge,
    }
  },
})
