
import { defineComponent, computed, reactive } from 'vue'

// Composables
import { useTitle } from '@vueuse/core'
import { useRouter } from '@/use/router'
import { useDateRange } from '@/use/date-range'
import { useProject } from '@/org/use-projects'
import { useMetrics } from '@/metrics/use-metrics'
import { useMetricMonitor, useMonitorManager, MonitorState } from '@/alerting/use-monitors'

// Components
import DateRangePicker from '@/components/date/DateRangePicker.vue'
import MonitorMetricForm from '@/alerting/MonitorMetricForm.vue'
import MonitorStateAvatar from '@/alerting/MonitorStateAvatar.vue'

export default defineComponent({
  name: 'MonitorMetricShow',
  components: {
    DateRangePicker,
    MonitorMetricForm,
    MonitorStateAvatar,
  },

  setup() {
    useTitle('Metrics Monitor')
    const { router } = useRouter()
    const dateRange = useDateRange()
    const project = useProject()

    const breadcrumbs = computed(() => {
      const bs: any[] = []

      bs.push({
        text: project.data?.name ?? 'Project',
        to: {
          name: 'ProjectShow',
        },
        exact: true,
      })

      bs.push({
        text: 'Monitors',
        to: {
          name: 'MonitorList',
        },
        exact: true,
      })

      bs.push({ text: 'Edit metric monitor' })

      return bs
    })

    const metrics = useMetrics()
    const monitor = useMetricMonitor()
    const monitorMan = useMonitorManager()

    function onSave() {
      redirectToMonitors()
    }
    function onCancel() {
      redirectToMonitors()
    }
    function redirectToMonitors() {
      router.push({ name: 'MonitorList' })
    }

    function activateMonitor() {
      if (!monitor.data) {
        return
      }
      monitorMan.activate(monitor.data).then(() => {
        monitor.reload()
      })
    }

    function pauseMonitor() {
      if (!monitor.data) {
        return
      }
      monitorMan.pause(monitor.data).then(() => {
        monitor.reload()
      })
    }

    return {
      MonitorState,

      dateRange,
      breadcrumbs,

      metrics,
      monitor,

      onSave,
      onCancel,

      monitorMan,
      activateMonitor,
      pauseMonitor,
      reactive,
    }
  },
})
