
import { defineComponent, shallowRef, PropType } from 'vue'

// Composables
import { useRouter } from '@/use/router'
import { useDashManager, UseDashboards, UseDashboard } from '@/metrics/use-dashboards'

// Components
import DashNewForm from '@/metrics/DashNewForm.vue'
import DashEditForm from '@/metrics/DashEditForm.vue'

// Types
import { Dashboard } from '@/metrics/types'

export default defineComponent({
  name: 'DashMenu',
  components: {
    DashNewForm,
    DashEditForm,
  },

  props: {
    dashboards: {
      type: Object as PropType<UseDashboards>,
      required: true,
    },
    dashboard: {
      type: Object as PropType<UseDashboard>,
      required: true,
    },
  },

  setup(props) {
    const { router } = useRouter()
    const menu = shallowRef(false)
    const newDialog = shallowRef(false)
    const editDialog = shallowRef(false)

    const dashMan = useDashManager()

    function onUpdateDash() {
      editDialog.value = false
      menu.value = false
      props.dashboards.reload()
    }

    function onCreateDash(dash: Dashboard) {
      newDialog.value = false
      menu.value = false
      props.dashboards.reload().then(() => {
        router.push({ name: 'MetricsDashShow', params: { dashId: String(dash.id) } })
      })
    }

    function closeDialog() {
      editDialog.value = false
      newDialog.value = false
      menu.value = false
    }

    function cloneDash() {
      if (!props.dashboard.data) {
        return
      }

      dashMan.clone(props.dashboard.data).then((dash) => {
        props.dashboards.reload().then(() => {
          router.push({ name: 'MetricsDashShow', params: { dashId: String(dash.id) } })
        })
      })
    }

    function deleteDash() {
      if (!props.dashboard.data) {
        return
      }

      dashMan.delete(props.dashboard.data).then(() => {
        props.dashboards.reload()
      })
    }

    return {
      menu,
      newDialog,
      editDialog,

      dashMan,

      onUpdateDash,
      onCreateDash,
      closeDialog,

      cloneDash,
      deleteDash,
    }
  },
})
