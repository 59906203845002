
import { clone } from 'lodash-es'
import { defineComponent, computed, watch, proxyRefs, PropType } from 'vue'

// Composables
import { useRouter, useRoute } from '@/use/router'
import { useTitle } from '@vueuse/core'
import { UseDateRange } from '@/use/date-range'
import { useUser } from '@/org/use-users'
import { useSystems, SystemsFilter } from '@/tracing/system/use-systems'
import { useUql } from '@/use/uql'

// Components
import DateRangePicker from '@/components/date/DateRangePicker.vue'
import SystemPicker from '@/tracing/system/SystemPicker.vue'
import HelpCard from '@/tracing/HelpCard.vue'
import SavedViews from '@/tracing/views/SavedViews.vue'
import UptraceQuery from '@/components/UptraceQuery.vue'
import SpanQueryBuilder from '@/tracing/query/SpanQueryBuilder.vue'

interface Props {
  itemListRouteName: string
  groupListRouteName: string
}

export default defineComponent({
  name: 'Tracing',
  components: {
    DateRangePicker,
    SystemPicker,
    HelpCard,
    SavedViews,
    UptraceQuery,
    SpanQueryBuilder,
  },

  props: {
    dateRange: {
      type: Object as PropType<UseDateRange>,
      required: true,
    },
    systemsFilter: {
      type: Function as PropType<SystemsFilter>,
      default: undefined,
    },
    allSystem: {
      type: String,
      required: true,
    },
    eventsMode: {
      type: Boolean,
      required: true,
    },
    defaultQuery: {
      type: String,
      required: true,
    },
    itemListRouteName: {
      type: String,
      required: true,
    },
    groupListRouteName: {
      type: String,
      required: true,
    },
  },

  setup(props) {
    useTitle('Explore spans')
    props.dateRange.syncQueryParams()
    props.dateRange.roundUp()

    const route = useRoute()
    const user = useUser()

    const uql = useUql()
    uql.syncQueryParams()

    const systems = useSystems(() => {
      return {
        ...props.dateRange.axiosParams(),
        query: uql.whereQuery,
      }
    })
    systems.syncQueryParams()

    const axiosParams = computed(() => {
      return {
        ...props.dateRange.axiosParams(),
        ...uql.axiosParams(),
        system: systems.activeSystem,
      }
    })

    const systemsItems = computed(() => {
      if (props.systemsFilter) {
        return props.systemsFilter(systems.items)
      }
      return systems.items
    })

    watch(
      () => props.defaultQuery,
      () => {
        if (!route.value.query.query) {
          resetQuery()
        }
      },
      { immediate: true, flush: 'pre' },
    )

    function resetQuery() {
      uql.query = props.defaultQuery
    }

    return {
      user,
      systems,
      systemsItems,

      uql,
      axiosParams,

      routes: useRoutes(props),

      resetQuery,
    }
  },
})

function useRoutes(props: Props) {
  const { route } = useRouter()

  const spanList = computed(() => {
    const query = clone(route.value.query)
    if (query.sort_by) {
      delete query.sort_by
    }

    return {
      name: props.itemListRouteName,
      query,
    }
  })

  const groupList = computed(() => {
    return {
      name: props.groupListRouteName,
      query: route.value.query,
    }
  })

  return proxyRefs({
    groupList,
    spanList,
  })
}
