
import { defineComponent, shallowRef, computed, PropType } from 'vue'

// Composables
import { useRouter } from '@/use/router'
import { UseDateRange } from '@/use/date-range'
import { buildWhere, exploreAttr } from '@/use/uql'

// Utilities
import { AttrKey, isEventSystem } from '@/models/otel'
import { truncateMiddle } from '@/util/string'

interface MenuItem {
  title: string
  link: Record<string, any>
}

export default defineComponent({
  name: 'KeyValueFilterLinkItem',

  props: {
    dateRange: {
      type: Object as PropType<UseDateRange>,
      required: true,
    },
    projectId: {
      type: [Number, String],
      default: undefined,
    },
    system: {
      type: String,
      required: true,
    },
    groupId: {
      type: String,
      default: undefined,
    },
    name: {
      type: String,
      required: true,
    },
    value: {
      type: undefined,
      required: true,
    },
    filterable: {
      type: Boolean,
      default: true,
    },
  },

  setup(props) {
    const menu = shallowRef(false)
    const { route } = useRouter()

    const isEvent = computed(() => {
      return isEventSystem(props.system)
    })

    const query = computed(() => {
      if (route.value.query.query) {
        return route.value.query.query
      }
      const query = exploreAttr(AttrKey.spanGroupId, isEvent.value)
      if (props.groupId) {
        return query + ` | where ${AttrKey.spanGroupId} = ${props.groupId}`
      }
      return query
    })

    const menuItems = computed((): MenuItem[] => {
      if (!props.filterable) {
        return []
      }

      const groupLink = link({ query: exploreAttr(props.name, isEvent.value) })
      groupLink.to.name = isEvent.value ? 'EventGroupList' : 'SpanGroupList'

      const items = [
        {
          title: `Group by ${props.name}`,
          link: groupLink,
        },
      ]

      const ops = ['=', '!=']
      if (typeof props.value === 'number') {
        ops.push('<', '<=', '>', '>=')
      }

      for (let op of ops) {
        items.push({
          title: `${props.name} ${op} ${format(props.value)}`,
          link: link({
            query: [query.value, buildWhere(props.name, op, props.value as any)].join(' | '),
          }),
        })
      }

      for (let op of ['exists', 'not exists']) {
        items.push({
          title: `${props.name} ${op}`,
          link: link({
            query: [query.value, buildWhere(props.name, op)].join(' | '),
          }),
        })
      }

      return items
    })

    function format(v: any): string {
      return truncateMiddle(String(v))
    }

    function link(query: Record<string, any> = {}) {
      query = {
        ...query,
        ...props.dateRange.queryParams(),
        system: props.system,
      }

      return {
        to: {
          name: route.value.name === 'SpanList' ? 'SpanList' : 'SpanGroupList',
          query,
        },
        exact: true,
      }
    }

    return { menu, menuItems }
  },
})
