
import { defineComponent, shallowRef, reactive } from 'vue'

// Composables
import {
  emptySlackNotifChannel,
  emptyWebhookNotifChannel,
  emptyAlertmanagerNotifChannel,
} from '@/alerting/use-notif-channels'

export default defineComponent({
  name: 'NotifChannelNewMenu',

  setup() {
    const menu = shallowRef(false)
    return {
      menu,
      emptySlackNotifChannel,
      emptyWebhookNotifChannel,
      emptyAlertmanagerNotifChannel,
      reactive,
    }
  },
})
