
import { defineComponent, shallowRef, computed, PropType } from 'vue'

// Composables
import { AxiosParams } from '@/use/axios'
import { UseUql } from '@/use/uql'
import { ActiveMetric as Metric } from '@/metrics/types'

// Components
import FacetList from '@/components/facet/FacetList.vue'

export default defineComponent({
  name: 'MetricsWhereMenu',
  components: { FacetList },

  props: {
    uql: {
      type: Object as PropType<UseUql>,
      required: true,
    },
    axiosParams: {
      type: Object as PropType<AxiosParams>,
      required: true,
    },
    metrics: {
      type: Array as PropType<Metric[]>,
      required: true,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },

  setup(props) {
    const menu = shallowRef(false)
    const drawer = shallowRef(false)

    function onClickOutside() {
      drawer.value = false
    }

    function closeConditional() {
      return drawer.value
    }

    const activeMetric = shallowRef<Metric>()

    const facetParams = computed(() => {
      if (!drawer.value) {
        return null
      }
      return {
        ...props.axiosParams,
        query: props.uql.whereQuery,
        metric: activeMetric.value?.name,
      }
    })

    return {
      menu,

      drawer,
      onClickOutside,
      closeConditional,

      activeMetric,
      facetParams,
    }
  },
})
