
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'UptraceLogoSmall',

  props: {
    width: {
      type: [Number, String],
      default: 38,
    },
  },
})
