
import { defineComponent, shallowRef, PropType } from 'vue'

// Composables
import { useNotifChannelManager, WebhookNotifChannel } from '@/alerting/use-notif-channels'

// Utilities
import { requiredRule } from '@/util/validation'

export default defineComponent({
  name: 'NotifChannelWebhookForm',

  props: {
    channel: {
      type: Object as PropType<WebhookNotifChannel>,
      required: true,
    },
  },

  setup(props, ctx) {
    const man = useNotifChannelManager()

    const form = shallowRef()
    const isValid = shallowRef(true)
    const rules = {
      name: [requiredRule],
      url: [requiredRule],
      payload: [
        (v: any) => {
          if (!v) {
            return true
          }
          try {
            JSON.parse(v)
          } catch (err) {
            return String(err)
          }
          return true
        },
      ],
    }

    function submit() {
      save().then(() => {
        ctx.emit('click:save')
        ctx.emit('click:close')
      })
    }

    function save() {
      if (!form.value.validate()) {
        return Promise.reject()
      }

      if (props.channel.id) {
        return man.webhookUpdate(props.channel)
      }
      return man.webhookCreate(props.channel)
    }

    return {
      man,

      form,
      isValid,
      rules,
      submit,

      alertExample,
    }
  },
})

const alertExample = `{
  "id": "1676471814931265794",
  "eventName": "created",
  "payload": { "custom_key": "custom_value" },
  "createdAt": "2023-02-15T14:36:54.931265914Z",

  "alert": {
    "id": "123",
    "url": "https://app.uptrace.dev/alerting/1/alerts/123",
    "name": "Test message",
    "type": "metric",
    "state": "open",
    "createdAt": "2023-02-15T14:36:54.931265914Z"
  }
}
`.trim()
