import { render, staticRenderFns } from "./NotifChannelEmailForm.vue?vue&type=template&id=c2a16ec4&scoped=true&"
import script from "./NotifChannelEmailForm.vue?vue&type=script&lang=ts&"
export * from "./NotifChannelEmailForm.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../node_modules/.pnpm/vue-loader@15.10.1_dbdd70d792ef5b81db31ca75740cc896/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "c2a16ec4",
  null
  
)

export default component.exports