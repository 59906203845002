
import { defineComponent, shallowRef, computed } from 'vue'

// Composables
import { useTitle } from '@vueuse/core'
import { useForceReload } from '@/use/force-reload'
import { useNotifChannels, NotifChannel, NotifChannelType } from '@/alerting/use-notif-channels'

// Components
import ForceReloadBtn from '@/components/date/ForceReloadBtn.vue'
import NotifChannelNewMenu from '@/alerting/NotifChannelNewMenu.vue'
import NotifChannelSlackForm from '@/alerting/NotifChannelSlackForm.vue'
import NotifChannelWebhookForm from '@/alerting/NotifChannelWebhookForm.vue'
import NotifChannelAlertmanagerForm from '@/alerting/NotifChannelAlertmanagerForm.vue'
import NotifChannelsTable from '@/alerting/NotifChannelsTable.vue'

export default defineComponent({
  name: 'ChannelList',
  components: {
    ForceReloadBtn,
    NotifChannelNewMenu,
    NotifChannelSlackForm,
    NotifChannelWebhookForm,
    NotifChannelAlertmanagerForm,
    NotifChannelsTable,
  },

  setup() {
    useTitle('Notification Channels')
    const { forceReloadParams } = useForceReload()

    const channels = useNotifChannels(() => {
      return forceReloadParams.value
    })

    const internalDialog = shallowRef(false)
    const activeChannel = shallowRef<NotifChannel>()
    const dialog = computed({
      get(): boolean {
        return Boolean(internalDialog.value && activeChannel.value)
      },
      set(dialog: boolean) {
        internalDialog.value = dialog
        if (!dialog) {
          activeChannel.value = undefined
        }
      },
    })

    return {
      NotifChannelType,

      channels,

      dialog,
      activeChannel,
    }
  },
})
