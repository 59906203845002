
import { defineComponent, shallowRef } from 'vue'

export default defineComponent({
  name: 'MonitorNewMenu',

  setup() {
    const menu = shallowRef(false)
    return { menu }
  },
})
