
import numbro from 'numbro'
import { defineComponent, shallowRef, reactive, computed, watch, PropType } from 'vue'

// Composables
import { UseDateRange } from '@/use/date-range'
import { useUql } from '@/use/uql'
import { useNotifChannels } from '@/alerting/use-notif-channels'
import { useActiveMetrics, UseMetrics } from '@/metrics/use-metrics'
import { useTimeseries, useStyledTimeseries } from '@/metrics/use-query'
import { useMonitorManager, MetricMonitor } from '@/alerting/use-monitors'

// Components
import UnitPicker from '@/components/UnitPicker.vue'
import MetricsPicker from '@/metrics/MetricsPicker.vue'
import MetricsQueryBuilder from '@/metrics/query/MetricsQueryBuilder.vue'
import MetricChart from '@/metrics/MetricChart.vue'
import ChartLegendTable from '@/metrics/ChartLegendTable.vue'

// Utilities
import { EventBus } from '@/models/eventbus'
import { updateColumnMap, MetricColumn } from '@/metrics/types'
import { requiredRule } from '@/util/validation'

export default defineComponent({
  name: 'MonitorMetricForm',
  components: {
    UnitPicker,
    MetricsPicker,
    MetricsQueryBuilder,
    MetricChart,
    ChartLegendTable,
  },

  props: {
    dateRange: {
      type: Object as PropType<UseDateRange>,
      required: true,
    },
    metrics: {
      type: Object as PropType<UseMetrics>,
      required: true,
    },
    monitor: {
      type: Object as PropType<MetricMonitor>,
      required: true,
    },
    columnMap: {
      type: Object as PropType<Record<string, MetricColumn>>,
      default: () => {
        return reactive({})
      },
    },
  },

  setup(props, ctx) {
    const eventBus = new EventBus()
    const channels = useNotifChannels(() => {
      return {}
    })

    const form = shallowRef()
    const isValid = shallowRef(false)
    const rules = {
      name: [requiredRule],
      minValue: [
        (v: any) => {
          if (
            typeof props.monitor.params.minValue !== 'number' &&
            typeof props.monitor.params.maxValue !== 'number'
          ) {
            return 'At least min value is required'
          }
          return true
        },
      ],
      maxValue: [
        (v: any) => {
          if (
            typeof props.monitor.params.minValue !== 'number' ||
            typeof props.monitor.params.maxValue !== 'number'
          ) {
            return true
          }
          if (props.monitor.params.maxValue <= props.monitor.params.minValue) {
            return 'Max value should be greater than min'
          }
          return true
        },
      ],
    }
    const forMinuteItems = [
      { text: '1 minute', value: 1 },
      { text: '3 minutes', value: 3 },
      { text: '5 minutes', value: 5 },
      { text: '10 minutes', value: 10 },
      { text: '15 minutes', value: 15 },
    ]

    const uql = useUql()
    const monitorMan = useMonitorManager()
    const activeMetrics = useActiveMetrics(computed(() => props.monitor.params.metrics))
    const axiosParams = computed(() => {
      if (!props.monitor.params.query) {
        return undefined
      }

      const metrics = props.monitor.params.metrics.filter((m) => m.name && m.alias)
      if (!metrics.length) {
        return undefined
      }

      return {
        ...props.dateRange.axiosParams(),
        metric: metrics.map((m) => m.name),
        alias: metrics.map((m) => m.alias),
        query: props.monitor.params.query,
      }
    })

    const timeseries = useTimeseries(() => {
      return axiosParams.value
    })

    const styledTimeseries = useStyledTimeseries(
      computed(() => timeseries.items),
      computed(() => props.columnMap),
      computed(() => ({})),
    )

    const activeColumn = computed(() => {
      const columns = Object.keys(props.columnMap)

      if (columns.length !== 1) {
        return undefined
      }

      const colName = columns[0]
      const col = props.columnMap[colName]
      return {
        ...col,
        name: colName,
      }
    })

    const observedMin = computed(() => {
      let min = Number.MAX_VALUE
      for (let ts of timeseries.items) {
        for (let num of ts.value) {
          if (num === 0) {
            continue
          }
          if (num < min) {
            min = num
          }
        }
      }
      if (min !== Number.MAX_VALUE) {
        return min
      }
      return undefined
    })

    const observedMax = computed(() => {
      let max = 0
      for (let ts of timeseries.items) {
        if (ts.max > max) {
          max = ts.max
        }
      }
      return max
    })

    const observedAvg = computed(() => {
      let sum = 0
      let count = 0
      for (let ts of timeseries.items) {
        for (let num of ts.value) {
          sum += num
          count++
        }
      }
      return sum / count
    })

    watch(
      () => props.monitor.params.query,
      (query) => {
        uql.query = query
      },
      { immediate: true },
    )

    watch(
      () => uql.query,
      (query) => {
        props.monitor.params.query = query
      },
    )

    watch(
      () => timeseries.query,
      (queryInfo) => {
        if (queryInfo) {
          uql.setQueryInfo(queryInfo)
        }
      },
      { immediate: true },
    )

    watch(
      () => timeseries.columns,
      (columns) => {
        updateColumnMap(props.columnMap, columns)

        const params = props.monitor.params
        if (params.column && params.columnUnit) {
          props.columnMap[params.column] = {
            unit: params.columnUnit,
            color: '',
          }
        }
      },
    )

    watch(
      () => props.monitor.params.minValue,
      () => form.value.validate(),
    )
    watch(
      () => props.monitor.params.maxValue,
      () => form.value.validate(),
    )

    function submit() {
      save().then(() => {
        ctx.emit('click:save')
      })
    }

    function save() {
      if (!form.value.validate()) {
        return Promise.reject()
      }
      if (!activeColumn.value) {
        return Promise.reject()
      }

      props.monitor.params.column = activeColumn.value.name
      props.monitor.params.columnUnit = activeColumn.value.unit

      if (props.monitor.id) {
        return monitorMan.updateMetricMonitor(props.monitor)
      }
      return monitorMan.createMetricMonitor(props.monitor)
    }

    function formatNum(n: number) {
      return numbro(n).format({
        thousandSeparated: true,
        mantissa: mantissa(n),
        trimMantissa: true,
      })
    }

    function mantissa(n: number) {
      if (n < 0.1) {
        return 3
      }
      if (n < 1) {
        return 2
      }
      if (n < 10) {
        return 1
      }
      return 0
    }

    return {
      eventBus,
      channels,

      form,
      isValid,
      rules,
      forMinuteItems,
      submit,

      uql,
      monitorMan,
      activeMetrics,
      axiosParams,
      timeseries,
      styledTimeseries,
      activeColumn,
      observedMin,
      observedMax,
      observedAvg,
      formatNum,
    }
  },
})
