var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('tr',{staticClass:"cursor-pointer",on:{"click":function($event){_vm.$router.push(_vm.monitorRouteFor(_vm.monitor))}}},[_c('td',[_vm._v(" "+_vm._s(_vm.monitor.name)+" ")]),_c('td',{staticClass:"text-no-wrap"},[_c('MonitorTypeIcon',{staticClass:"mr-2",attrs:{"type":_vm.monitor.type}}),_c('span',[_vm._v(_vm._s(_vm.monitor.type))])],1),_c('td',{staticClass:"text-center"},[_c('MonitorStateAvatar',{attrs:{"state":_vm.monitor.state}})],1),_c('td',{staticClass:"text-center text-no-wrap"},[(_vm.monitor.state != _vm.MonitorState.Paused)?_c('v-btn',{attrs:{"icon":"","title":"Pause monitor"},on:{"click":function($event){$event.stopPropagation();return _vm.pauseMonitor(_vm.monitor)}}},[_c('v-icon',[_vm._v("mdi-pause")])],1):_c('v-btn',{attrs:{"icon":"","title":"Resume monitor"},on:{"click":function($event){$event.stopPropagation();return _vm.activateMonitor(_vm.monitor)}}},[_c('v-icon',[_vm._v("mdi-play")])],1),_c('v-btn',{attrs:{"to":_vm.monitorRouteFor(_vm.monitor),"icon":"","title":"Edit monitor"},on:{"click":function($event){$event.stopPropagation();}}},[_c('v-icon',[_vm._v("mdi-pencil-outline")])],1),_c('v-btn',{attrs:{"loading":_vm.monitorMan.pending,"icon":"","title":"Delete monitor"},on:{"click":function($event){$event.stopPropagation();return _vm.deleteMonitor.apply(null, arguments)}}},[_c('v-icon',[_vm._v("mdi-delete-outline")])],1)],1),_c('td',{staticClass:"text-center"},[(_vm.monitor.alertCount)?_c('router-link',{staticClass:"link",attrs:{"to":{
        name: 'AlertList',
        query: {
          q: 'monitor:' + _vm.monitor.id,
          state: null,
        },
      }}},[_vm._v(_vm._s(_vm.monitor.alertCount)+" alerts")]):_vm._e()],1),_c('td',[(_vm.monitor.updatedAt)?_c('XDate',{attrs:{"date":_vm.monitor.updatedAt,"format":"relative"}}):_vm._e()],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }