
import { defineComponent, shallowRef, computed, watch, PropType } from 'vue'

// Composables
import { useRoute } from '@/use/router'
import { useDataSource } from '@/use/datasource'
import { useForceReload } from '@/use/force-reload'
import { UseUql } from '@/use/uql'
import { useMetrics, useActiveMetrics, defaultMetricQuery } from '@/metrics/use-metrics'
import { hasMetricAlias } from '@/metrics/use-query'
import { MetricAlias } from '@/metrics/types'

// Components
import MetricPicker from '@/metrics/MetricPicker.vue'

export default defineComponent({
  name: 'MetricsPicker',
  components: { MetricPicker },

  props: {
    value: {
      type: Array as PropType<MetricAlias[]>,
      required: true,
    },
    tableGrouping: {
      type: Array as PropType<string[]>,
      default: () => [],
    },
    uql: {
      type: Object as PropType<UseUql>,
      required: true,
    },
    editable: {
      type: Boolean,
      default: false,
    },
  },

  setup(props, ctx) {
    const route = useRoute()
    const { forceReloadParams } = useForceReload()

    const metricPicker = shallowRef()
    function validate(): boolean {
      if (!metricPicker.value) {
        return true
      }
      return metricPicker.value.validate()
    }

    const activeAttrKeys = shallowRef<string[]>([])
    watch(
      () => props.tableGrouping,
      (grouping) => {
        activeAttrKeys.value = grouping
      },
      { immediate: true },
    )

    const attrKeysDs = useDataSource(() => {
      const { projectId } = route.value.params
      return {
        url: `/api/v1/metrics/${projectId}/attr-keys`,
        params: {
          ...forceReloadParams.value,
        },
      }
    })
    const metrics = useMetrics(() => {
      return {
        attr_key: activeAttrKeys.value,
      }
    })
    const activeMetrics = useActiveMetrics(computed(() => props.value))

    function addMetric(metricAlias: MetricAlias) {
      const metric = metrics.items.find((m) => m.name === metricAlias.name)
      if (!metric) {
        return
      }

      const activeMetrics = props.value.slice()
      activeMetrics.push(metricAlias)
      ctx.emit('input', activeMetrics)

      const column = defaultMetricQuery(metric.instrument, metricAlias.alias)
      props.uql.query = props.uql.query + ' | ' + column
    }

    function removeMetric(index: number, metric: MetricAlias) {
      const activeMetrics = props.value.slice()
      activeMetrics.splice(index, 1)
      ctx.emit('input', activeMetrics)

      props.uql.parts = props.uql.parts.filter((part) => {
        return !hasMetricAlias(part.query, metric.alias)
      })
    }

    return {
      activeAttrKeys,
      attrKeysDs,
      metrics,

      activeMetrics,
      addMetric,
      removeMetric,

      metricPicker,
      validate,
    }
  },
})
