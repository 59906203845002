
import Vue from 'vue'
import { defineComponent, ref, computed } from 'vue'

// Composables
import { useRouter } from '@/use/router'
import { useUser } from '@/org/use-users'
import { Project } from '@/org/use-projects'

interface Autocomplete {
  focus: () => void
  blur: () => void
  activateMenu: () => void
}

export default defineComponent({
  name: 'ProjectPicker',

  setup() {
    const { router, route } = useRouter()
    const user = useUser()

    const el = ref<Autocomplete>()
    const searchInput = ref('')
    const selectingProject = ref(false)

    const activeProject = computed({
      get(): Project | undefined {
        return user.activeProject
      },
      set(project: Project) {
        let routeName = route.value.name as string

        const paramKeys = Object.keys(route.value.params)
        if (paramKeys.length !== 1 || paramKeys[0] !== 'projectId') {
          routeName = 'Overview'
        }

        router
          .push({
            name: routeName,
            params: { projectId: project.id.toString() },
          })
          .catch(() => {})
      },
    } as any)

    const autocompleteActive = computed(() => {
      return !activeProject.value || selectingProject.value
    })

    function onBlur() {
      setTimeout(() => {
        selectingProject.value = false
      }, 200)
    }

    function onChange() {
      Vue.nextTick(() => {
        if (el.value) {
          el.value.blur()
        }
      })
    }

    function onClickActiveProject() {
      selectingProject.value = true

      Vue.nextTick(() => {
        if (el.value) {
          el.value.focus()
          el.value.activateMenu()
        }
      })
    }

    return {
      route,

      el,
      user,
      activeProject,

      searchInput,
      autocompleteActive,
      onBlur,
      onChange,
      onClickActiveProject,
    }
  },
})
