
import { defineComponent, computed } from 'vue'

export default defineComponent({
  name: 'TraceError',
  props: {
    error: {
      type: Error,
      required: true,
    },
  },

  setup(props) {
    const error = computed((): any => {
      return props.error
    })

    const title = computed(() => {
      return error.value?.response?.data?.status ?? ''
    })

    const message = computed(() => {
      return error.value?.response?.data?.message ?? String(props.error)
    })

    return { title, message }
  },
})
