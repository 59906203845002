
import { defineComponent, PropType } from 'vue'

// Composables
import { useConfirm } from '@/use/confirm'
import {
  useMonitorManager,
  monitorRouteFor,
  Monitor,
  MonitorType,
  MonitorState,
} from '@/alerting/use-monitors'

// Components
import MonitorTypeIcon from '@/alerting/MonitorTypeIcon.vue'
import MonitorStateAvatar from '@/alerting/MonitorStateAvatar.vue'

export default defineComponent({
  name: 'MonitorsTableRow',
  components: { MonitorTypeIcon, MonitorStateAvatar },

  props: {
    monitor: {
      type: Object as PropType<Monitor>,
      required: true,
    },
  },

  setup(props, ctx) {
    const confirm = useConfirm()
    const monitorMan = useMonitorManager()

    function activateMonitor() {
      monitorMan.activate(props.monitor).then(() => {
        ctx.emit('change', props.monitor)
      })
    }

    function pauseMonitor(monitor: Monitor) {
      monitorMan.pause(monitor).then(() => {
        ctx.emit('change', props.monitor)
      })
    }

    function deleteMonitor() {
      confirm
        .open('Delete monitor', `Do you really want to delete "${props.monitor.name}" monitor?`)
        .then(() => monitorMan.del(props.monitor))
        .then((monitor) => ctx.emit('change', monitor))
        .catch(() => {})
    }

    return {
      MonitorType,
      MonitorState,
      monitorRouteFor,

      monitorMan,
      activateMonitor,
      pauseMonitor,
      deleteMonitor,
    }
  },
})
