
import { filter as fuzzyFilter } from 'fuzzaldrin-plus'
import { defineComponent, shallowRef, computed, PropType } from 'vue'

// Composables
import { UseUql } from '@/use/uql'
import { defaultMetricAlias } from '@/metrics/use-metrics'

// Utilities
import { unitShortName } from '@/util/fmt'
import { requiredRule, optionalRule } from '@/util/validation'

// Types
import { Metric, MetricAlias } from '@/metrics/types'

export default defineComponent({
  name: 'MetricPicker',

  props: {
    loading: {
      type: Boolean,
      default: false,
    },
    metrics: {
      type: Array as PropType<Metric[]>,
      required: true,
    },
    activeMetrics: {
      type: Array as PropType<MetricAlias[]>,
      required: true,
    },
    uql: {
      type: Object as PropType<UseUql>,
      required: true,
    },
    required: {
      type: Boolean,
      required: true,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },

  setup(props, ctx) {
    const metricAliasRef = shallowRef()
    const metricName = shallowRef('')
    const metricAlias = shallowRef('')
    const searchInput = shallowRef('')

    const form = shallowRef()
    const isValid = shallowRef(false)
    const rules = computed(() => {
      return {
        name: [props.required ? requiredRule : optionalRule],
        alias: [
          (v: string) => {
            if (!metricName.value) {
              return true
            }
            if (!v) {
              return 'Alias is required'
            }
            if (!/^[a-z][a-z0-9_]*$/i.test(v)) {
              return 'Only letters and numbers are allowed'
            }

            const found = props.activeMetrics.find((m) => m.alias === v)
            if (found) {
              return 'Alias is duplicated'
            }

            return true
          },
        ],
      }
    })

    const filteredMetrics = computed((): Metric[] => {
      let metrics = props.metrics
      if (searchInput.value) {
        metrics = fuzzyFilter(metrics, searchInput.value, { key: 'name' })
      }
      return metrics
    })

    function submit() {
      if (!validate()) {
        return
      }

      metricAlias.value = metricAlias.value.toLowerCase()
      ctx.emit('click:add', { name: metricName.value, alias: metricAlias.value })
      reset()
    }

    function onMetricNameChange(metricName: string | null) {
      if (!metricName) {
        return
      }

      metricAlias.value = defaultMetricAlias(metricName)
      metricAliasRef.value.focus()
    }

    function validate() {
      return form.value.validate()
    }

    function reset() {
      metricName.value = ''
      metricAlias.value = ''
      form.value.reset()
    }

    return {
      searchInput,
      metricAliasRef,
      metricName,
      metricAlias,

      form,
      isValid,
      rules,
      filteredMetrics,

      submit,
      validate,
      reset,

      unitShortName,
      onMetricNameChange,
    }
  },
})
