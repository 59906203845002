
import { defineComponent, shallowRef, watch } from 'vue'

// Composables
import { useSnackbar } from '@/use/snackbar'
import { useYamlDashboard, useDashManager } from '@/metrics/use-dashboards'

export default defineComponent({
  name: 'DashYaml',

  props: {
    yamlUrl: {
      type: String,
      required: true,
    },
  },

  setup(_props, ctx) {
    const snackbar = useSnackbar()

    const yaml = shallowRef('')
    const dash = useYamlDashboard()
    const dashMan = useDashManager()

    watch(
      () => dash.yaml,
      (dashYaml) => {
        yaml.value = dashYaml
      },
      { immediate: true },
    )

    function saveYaml() {
      dashMan.updateYaml(yaml.value).then(() => {
        snackbar.notifySuccess(`The dashboard has beed successfully updated from the YAML`)
        ctx.emit('change')
      })
    }

    return { yaml, dash, dashMan, saveYaml }
  },
})
