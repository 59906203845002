
import { defineComponent, PropType } from 'vue'

// Composables
import { UseDataSource, Item } from '@/use/datasource'

// Utilities
import { truncateMiddle } from '@/util/string'

export default defineComponent({
  name: 'UptraceCombobox',

  props: {
    value: {
      type: Object as PropType<Item>,
      default: undefined,
    },
    dataSource: {
      type: Object as PropType<UseDataSource>,
      required: true,
    },
  },

  setup(props, { emit }) {
    function onInput(value: Item | string | undefined) {
      if (typeof value === 'string') {
        value = { value, text: value }
      }
      emit('input', value)
    }

    return {
      onInput,
      truncateMiddle,
    }
  },
})
