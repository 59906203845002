
import { defineComponent, watch } from 'vue'

// Composables
import { useRouter } from '@/use/router'
import { useTraceSearch } from '@/tracing/use-trace-search'

export default defineComponent({
  name: 'TraceFind',

  setup() {
    const { router, route } = useRouter()
    const traceSearch = useTraceSearch()

    watch(
      () => traceSearch.span,
      (span) => {
        if (!span) {
          return
        }

        if (span.standalone) {
          router.replace({
            name: 'SpanShow',
            params: {
              projectId: String(span.projectId),
              traceId: span.traceId,
              spanId: span.id,
            },
          })
        } else {
          router.replace({
            name: 'TraceShow',
            params: {
              projectId: String(span.projectId),
              traceId: span.traceId,
            },
          })
        }
      },
    )

    watch(
      () => route.value.params.traceId,
      (traceId) => {
        traceSearch.find(traceId)
      },
      { immediate: true },
    )

    function reload() {
      traceSearch.find(route.value.params.traceId)
    }

    return { traceSearch, reload }
  },
})
