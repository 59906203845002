
import { omit, truncate } from 'lodash-es'
import { defineComponent, computed, PropType } from 'vue'

// Composables
import { useRoute } from '@/use/router'
import { createUqlEditor, UseUql } from '@/use/uql'
import { useMetrics } from '@/metrics/use-metrics'
import { useGroupTimeseries, Group, ColumnInfo } from '@/tracing/use-explore-spans'

// Components
import SparklineChart from '@/components/SparklineChart.vue'
import NewMonitorMenu from '@/tracing/NewMonitorMenu.vue'

// Utilities
import { AttrKey } from '@/models/otel'
import { MetricColumn } from '@/metrics/types'

export default defineComponent({
  name: 'GroupsTableRow',
  components: { SparklineChart, NewMonitorMenu },

  props: {
    uql: {
      type: Object as PropType<UseUql>,
      default: undefined,
    },
    groupingColumns: {
      type: Array as PropType<string[]>,
      required: true,
    },
    plainColumns: {
      type: Array as PropType<string[]>,
      required: true,
    },
    plottableColumns: {
      type: Array as PropType<ColumnInfo[]>,
      required: true,
    },
    plottedColumns: {
      type: Array as PropType<string[]>,
      required: true,
    },
    eventsMode: {
      type: Boolean,
      required: true,
    },
    axiosParams: {
      type: Object,
      default: undefined,
    },

    headers: {
      type: Array,
      required: true,
    },
    columnMap: {
      type: Object as PropType<Record<string, MetricColumn>>,
      required: true,
    },
    group: {
      type: Object as PropType<Group>,
      required: true,
    },
    isExpanded: {
      type: Boolean,
      required: true,
    },
    expand: {
      type: Function,
      required: true,
    },
  },

  setup(props) {
    const route = useRoute()

    const timeseries = useGroupTimeseries(() => {
      if (!props.plottedColumns.length) {
        return undefined
      }

      const query = [props.axiosParams.query, props.group._query].filter((v) => v).join(' | ')
      return {
        ...props.axiosParams,
        query,
        column: props.plottedColumns,
      }
    })

    const headerValues = computed((): string[] => {
      return props.headers.map((header: any) => header.value)
    })

    const systemRoute = computed(() => {
      const query = createUqlEditor().exploreAttr(AttrKey.spanGroupId).toString()
      return {
        name: props.eventsMode ? 'EventGroupList' : 'SpanGroupList',
        query: {
          ...omit(route.value.query, 'columns'),
          query: query,
          system: props.group[AttrKey.spanSystem],
        },
      }
    })

    const itemListRoute = computed(() => {
      const editor = props.uql
        ? props.uql.createEditor()
        : createUqlEditor().exploreAttr(AttrKey.spanGroupId, props.eventsMode)

      for (let colName of props.groupingColumns) {
        const value = props.group[colName]
        editor.where(colName, '=', value)
      }

      const query: Record<string, any> = {
        ...route.value.query,
        query: editor.toString(),
      }

      const system = props.group[AttrKey.spanSystem]
      if (system) {
        query.system = system
      }

      return {
        name: props.eventsMode ? 'EventList' : 'SpanList',
        query,
      }
    })

    const metrics = useMetrics(() => {
      if (props.groupingColumns.some((colName) => colName.startsWith('span.'))) {
        return undefined
      }
      if (!props.group._query) {
        return undefined
      }
      return {
        query: props.group._query,
      }
    }, 500)

    return {
      AttrKey,

      timeseries,
      headerValues,

      systemRoute,
      itemListRoute,

      metrics,

      truncate,
    }
  },
})
