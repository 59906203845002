
import { defineComponent, computed } from 'vue'

// Components
import Devicon from '@/components/Devicon.vue'

export default defineComponent({
  name: 'DistroIcons',
  components: { Devicon },

  setup() {
    const distros = computed(() => {
      return [
        {
          name: 'Go',
          icon: '/devicon/go-original.svg',
          to: link('go'),
        },
        {
          name: 'Python',
          icon: '/devicon/python-original.svg',
          to: link('python'),
        },
        {
          name: 'Ruby',
          icon: '/devicon/ruby-original.svg',
          to: link('ruby'),
        },
        {
          name: 'Node.js',
          icon: '/devicon/nodejs-original.svg',
          to: link('js-node'),
        },
        {
          name: '.NET',
          icon: '/devicon/dot-net-original.svg',
          to: link('dotnet'),
        },
        {
          name: 'Java',
          icon: '/devicon/java-original.svg',
          to: link('java'),
        },
        {
          name: 'PHP',
          icon: '/devicon/php-original.svg',
          to: link('php'),
        },
      ]
    })

    function link(lang: string): string {
      return `https://uptrace.dev/get/uptrace-${lang}.html`
    }

    return { distros }
  },
})
