
import { defineComponent, shallowRef, PropType } from 'vue'

// Composables
import { useRouter } from '@/use/router'
import { AxiosParams } from '@/use/axios'
import { useDataSource, Item } from '@/use/datasource'
import { UseUql } from '@/use/uql'

// Components
import Combobox from '@/components/Combobox.vue'
import UqlChip from '@/components/UqlChip.vue'

// Utilities
import { AttrKey } from '@/models/otel'
import { requiredRule } from '@/util/validation'

const groupColumns = [
  AttrKey.spanSystem,
  AttrKey.spanGroupId,
  AttrKey.serviceName,
  AttrKey.hostName,
]

export default defineComponent({
  name: 'GroupByMenu',
  components: { Combobox, UqlChip },

  props: {
    uql: {
      type: Object as PropType<UseUql>,
      required: true,
    },
    axiosParams: {
      type: Object as PropType<AxiosParams>,
      required: true,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },

  setup(props) {
    const { route } = useRouter()

    const menu = shallowRef(false)
    const column = shallowRef<Item>()

    const form = shallowRef()
    const isValid = shallowRef(false)
    const rules = {
      column: [requiredRule],
    }

    const suggestions = useDataSource(
      () => {
        if (!menu.value) {
          return null
        }

        const { projectId } = route.value.params
        return {
          url: `/api/v1/tracing/${projectId}/attr-keys`,
          params: {
            ...props.axiosParams,
          },
        }
      },
      { suggestSearchInput: true },
    )

    function add() {
      updateQuery(false)
    }

    function replace() {
      updateQuery(true)
    }

    function updateQuery(replace = false) {
      if (!column.value) {
        return
      }

      const editor = props.uql.createEditor()
      if (replace) {
        editor.resetGroupBy(column.value.value)
      } else {
        editor.addGroupBy(column.value.value)
      }
      props.uql.commitEdits(editor)

      column.value = undefined
      form.value.resetValidation()
      menu.value = false
    }

    return {
      menu,

      form,
      isValid,
      rules,
      suggestions,

      groupColumns,
      column,

      add,
      replace,
    }
  },
})
