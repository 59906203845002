
import { format } from 'sql-formatter'
import { defineComponent, ref, computed, PropType } from 'vue'

// Composables
import { useRoute } from '@/use/router'
import { UseDateRange } from '@/use/date-range'
import { createUqlEditor } from '@/use/uql'

// Components
import NewMonitorMenu from '@/tracing/NewMonitorMenu.vue'
import LoadPctileChart from '@/components/LoadPctileChart.vue'
import AttrsTable from '@/tracing/AttrsTable.vue'
import EventPanels from '@/tracing/EventPanels.vue'

// Utilities
import { AttrKey, isEventSystem } from '@/models/otel'
import { spanName, eventOrSpanName, Span } from '@/models/span'

export default defineComponent({
  name: 'SpanCard',
  components: {
    NewMonitorMenu,
    AttrsTable,
    EventPanels,
    LoadPctileChart,
  },

  props: {
    dateRange: {
      type: Object as PropType<UseDateRange>,
      required: true,
    },
    span: {
      type: Object as PropType<Span>,
      required: true,
    },
    fluid: {
      type: Boolean,
      default: false,
    },
  },

  setup(props) {
    const route = useRoute()
    const activeTab = ref('attrs')

    const isEvent = computed((): boolean => {
      return isEventSystem(props.span.system)
    })

    const axiosParams = computed(() => {
      return {
        ...props.dateRange.axiosParams(),
        system: props.span.system,
        group_id: props.span.groupId,
      }
    })

    const dbStatement = computed((): string => {
      return props.span.attrs[AttrKey.dbStatement] ?? ''
    })

    const dbStatementPretty = computed((): string => {
      try {
        return format(dbStatement.value)
      } catch (err) {
        return ''
      }
    })

    const excStacktrace = computed((): string => {
      return props.span.attrs[AttrKey.exceptionStacktrace] ?? ''
    })

    const traceRoute = computed(() => {
      if (props.span.standalone) {
        return null
      }
      if (route.value.name === 'TraceShow' && route.value.params.traceId === props.span.traceId) {
        return null
      }

      return {
        name: 'TraceShow',
        params: {
          traceId: props.span.traceId,
        },
      }
    })

    const spanGroupRoute = computed(() => {
      switch (route.value.name) {
        case 'SpanList':
        case 'EventList':
        case 'SpanGroupList':
        case 'EventGroupList':
          return undefined
      }

      return {
        name: isEvent.value ? 'EventList' : 'SpanList',
        query: {
          ...props.dateRange.queryParams(),
          system: props.span.system,
          query: createUqlEditor()
            .exploreAttr(AttrKey.spanGroupId, isEvent.value)
            .where(AttrKey.spanGroupId, '=', props.span.groupId)
            .toString(),
        },
      }
    })

    return {
      AttrKey,
      activeTab,
      isEvent,

      axiosParams,

      dbStatement,
      dbStatementPretty,
      excStacktrace,

      spanGroupRoute,
      traceRoute,

      spanName,
      eventOrSpanName,
    }
  },
})
