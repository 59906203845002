import { render, staticRenderFns } from "./SavedViewsList.vue?vue&type=template&id=ad5ad0c8&scoped=true&"
import script from "./SavedViewsList.vue?vue&type=script&lang=ts&"
export * from "./SavedViewsList.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../node_modules/.pnpm/vue-loader@15.10.1_dbdd70d792ef5b81db31ca75740cc896/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "ad5ad0c8",
  null
  
)

export default component.exports