
import { defineComponent, computed, PropType } from 'vue'

// Composables
import { UseDateRange } from '@/use/date-range'

// Components
import KeyValueFilterLinkItem from '@/tracing/KeyValueFilterLinkItem.vue'

export default defineComponent({
  name: 'KeyValueFilterLink',
  components: { KeyValueFilterLinkItem },

  props: {
    dateRange: {
      type: Object as PropType<UseDateRange>,
      required: true,
    },
    projectId: {
      type: [Number, String],
      required: true,
    },
    system: {
      type: String,
      required: true,
    },
    groupId: {
      type: String,
      default: undefined,
    },
    name: {
      type: String,
      required: true,
    },
    value: {
      type: undefined,
      required: true,
    },
    isEvent: {
      type: Boolean,
      required: true,
    },
    filterable: {
      type: Boolean,
      default: true,
    },
  },

  setup(props) {
    const values = computed(() => {
      if (Array.isArray(props.value)) {
        return props.value
      }
      return [props.value]
    })

    return { values }
  },
})
