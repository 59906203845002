
import { defineComponent, shallowRef, reactive, PropType } from 'vue'

// Composables
import { UseDateRange } from '@/use/date-range'

// Utilities
import {
  defaultChartLegend,
  Dashboard,
  ChartGridColumn,
  TableGridColumn,
  HeatmapGridColumn,
  GridColumnType,
  ChartKind,
} from '@/metrics/types'

export default defineComponent({
  name: 'DashGridColumnNewMenu',

  props: {
    dateRange: {
      type: Object as PropType<UseDateRange>,
      required: true,
    },
    dashboard: {
      type: Object as PropType<Dashboard>,
      required: true,
    },
  },

  setup(props, ctx) {
    const menu = shallowRef(false)

    function newChart(): ChartGridColumn {
      return reactive({
        id: 0,
        projectId: 0,
        dashId: 0,

        name: '',
        description: '',

        width: 0,
        height: 0,
        xAxis: 0,
        yAxis: 0,

        gridQueryTemplate: '',

        type: GridColumnType.Chart,
        params: {
          chartKind: ChartKind.Line,
          metrics: [],
          query: '',
          columnMap: {},
          timeseriesMap: {},
          legend: defaultChartLegend(),
        },
      })
    }

    function newTable(): TableGridColumn {
      return reactive({
        id: 0,
        projectId: 0,
        dashId: 0,

        name: '',
        description: '',

        width: 0,
        height: 0,
        xAxis: 0,
        yAxis: 0,

        gridQueryTemplate: '',

        type: GridColumnType.Table,
        params: {
          metrics: [],
          query: '',
          columnMap: {},
        },
      })
    }

    function newHeatmap(): HeatmapGridColumn {
      return reactive({
        id: 0,
        projectId: 0,
        dashId: 0,

        name: '',
        description: '',

        width: 0,
        height: 0,
        xAxis: 0,
        yAxis: 0,

        gridQueryTemplate: '',

        type: GridColumnType.Heatmap,
        params: {
          metric: '',
          unit: '',
          query: '',
        },
      })
    }

    return {
      GridColumnType,

      menu,

      newChart,
      newTable,
      newHeatmap,
    }
  },
})
