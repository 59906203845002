
import { defineComponent, shallowRef, watch, PropType } from 'vue'

// Composables
import { useTitle } from '@vueuse/core'
import { useRouter, useRouteQuery } from '@/use/router'
import { UseDateRange } from '@/use/date-range'
import { useDashboards, useDashboard } from '@/metrics/use-dashboards'

// Components
import DateRangePicker from '@/components/date/DateRangePicker.vue'
import DashPicker from '@/metrics/DashPicker.vue'
import DashMenu from '@/metrics/DashMenu.vue'
import DashPinBtn from '@/metrics/DashPinBtn.vue'
import DashNewForm from '@/metrics/DashNewForm.vue'
import DashGrid from '@/metrics/DashGrid.vue'
import DashTable from '@/metrics/DashTable.vue'
import DashYaml from '@/metrics/DashYaml.vue'

// Types
import { Dashboard, DashKind } from '@/metrics/types'

export default defineComponent({
  name: 'MetricsDashboard',
  components: {
    DateRangePicker,
    DashPicker,
    DashMenu,
    DashPinBtn,
    DashNewForm,
    DashGrid,
    DashTable,
    DashYaml,
  },

  props: {
    dateRange: {
      type: Object as PropType<UseDateRange>,
      required: true,
    },
  },

  setup() {
    useTitle('Metrics')
    const { router } = useRouter()

    const dashboards = useDashboards()
    const dashboard = useDashboard()

    const activeTab = shallowRef(DashKind.Table)
    watch(
      () => dashboard.data?.id,
      () => {
        const dash = dashboard.data
        if (!dash) {
          return
        }
        switch (activeTab.value) {
          case DashKind.Table:
            if (!dash.tableMetrics.length || !dash.tableQuery) {
              if (dashboard.grid.length) {
                activeTab.value = DashKind.Grid
              }
            }
            return
          case DashKind.Grid:
            if (!dashboard.grid.length) {
              if (dash.tableMetrics.length || dash.tableQuery) {
                activeTab.value = DashKind.Table
              }
            }
            return
        }
      },
    )

    useRouteQuery().sync({
      fromQuery(params) {
        if (params.tab) {
          activeTab.value = params.tab
        }
      },
      toQuery() {
        if (activeTab.value) {
          return { tab: activeTab.value }
        }
        return {}
      },
    })

    function onCreateDash(dash: Dashboard) {
      dashboards.reload().then(() => {
        router.replace({ name: 'MetricsDashShow', params: { dashId: String(dash.id) } })
      })
    }

    function onCloneDash(dash: Dashboard) {
      dashboards.reload().then(() => {
        router.replace({ name: 'MetricsDashShow', params: { dashId: String(dash.id) } })
      })
    }

    function onPinDash() {
      dashboards.reload()
      dashboard.reload()
    }

    return {
      DashKind,
      activeTab,

      dashboards,
      dashboard,

      onCreateDash,
      onCloneDash,
      onPinDash,
    }
  },
})
