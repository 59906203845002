
import { defineComponent, PropType } from 'vue'

// Composables
import { UseDateRange } from '@/use/date-range'

// Components
import DashGridColumnChart from '@/metrics/DashGridColumnChart.vue'
import DashGridColumnTable from '@/metrics/DashGridColumnTable.vue'
import DashGridColumnHeatmap from '@/metrics/DashGridColumnHeatmap.vue'

// Utilities
import { GridColumn, GridColumnType } from '@/metrics/types'

export default defineComponent({
  name: 'DashGridColumnItem',
  components: {
    DashGridColumnChart,
    DashGridColumnTable,
    DashGridColumnHeatmap,
  },

  props: {
    dateRange: {
      type: Object as PropType<UseDateRange>,
      required: true,
    },
    gridColumn: {
      type: Object as PropType<GridColumn>,
      required: true,
    },
    height: {
      type: Number,
      required: true,
    },
    verbose: {
      type: Boolean,
      default: false,
    },
  },

  setup() {
    return { GridColumnType }
  },
})
