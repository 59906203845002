
import { defineComponent, shallowRef, computed, watch, PropType } from 'vue'

interface Item {
  value: string
  text: string
  short?: string
}

export default defineComponent({
  name: 'BtnSelectMenu',

  props: {
    value: {
      type: [Number, String],
      default: undefined,
    },
    items: {
      type: Array as PropType<Item[]>,
      required: true,
    },
    dense: {
      type: Boolean,
      default: false,
    },
    color: {
      type: String,
      default: 'grey lighten-4',
    },
    targetClass: {
      type: String,
      default: '',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },

  setup(props, ctx) {
    const menu = shallowRef(false)

    const active = computed(() => {
      return props.items.find((item) => item.value === props.value)
    })

    watch(
      active,
      (active) => {
        if (active === undefined && props.items.length) {
          ctx.emit('input', props.items[0].value)
        }
      },
      { immediate: true },
    )

    return { menu, active }
  },
})
