
import { defineComponent, shallowRef, computed, watch, PropType } from 'vue'

// Utilities
import { StyledTimeseries, LegendValue } from '@/metrics/types'
import { truncateMiddle } from '@/util/string'

export default defineComponent({
  name: 'ChartLegendTable',

  props: {
    loading: {
      type: Boolean,
      default: false,
    },
    timeseries: {
      type: Array as PropType<StyledTimeseries[]>,
      required: true,
    },
    values: {
      type: Array as PropType<LegendValue[]>,
      default: () => [LegendValue.Avg, LegendValue.Last, LegendValue.Min, LegendValue.Max],
    },
    maxLength: {
      type: Number,
      default: 40,
    },
  },

  setup(props, ctx) {
    const table = shallowRef()
    const selectedItems = shallowRef<StyledTimeseries[]>()

    const showExpand = computed(() => {
      return 'expanded-item' in ctx.slots
    })

    const headers = computed(() => {
      const headers = []

      headers.push({ text: 'metric', value: 'name', sortable: true, align: 'start' })

      for (let value of props.values) {
        switch (value) {
          case LegendValue.Avg:
            headers.push({ text: 'avg', value: 'avg', sortable: true, align: 'end' })
            break
          case LegendValue.Last:
            headers.push({ text: 'last', value: 'last', sortable: true, align: 'end' })
            break
          case LegendValue.Min:
            headers.push({ text: 'min', value: 'min', sortable: true, align: 'end' })
            break
          case LegendValue.Max:
            headers.push({ text: 'max', value: 'max', sortable: true, align: 'end' })
            break
          default:
            console.error(`unsupported legend value: ${value}`)
        }
      }

      if (showExpand.value) {
        headers.push({ text: '', value: 'data-table-expand', sortable: false })
      }

      return headers
    })

    function onCurrentItems(items: StyledTimeseries[]) {
      selectedItems.value = items
    }

    watch(selectedItems, (items) => {
      ctx.emit('current-items', items)
    })

    return {
      LegendValue,
      table,
      selectedItems,
      showExpand,
      headers,
      onCurrentItems,
      truncateMiddle,
    }
  },
})
