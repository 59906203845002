
import { defineComponent, shallowRef, PropType } from 'vue'

// Composables
import { Item, Category } from '@/components/facet/types'
import { usePinnedFacetManager } from '@/components/facet/use-pinned-facets'

// Components
import FacetItem from '@/components/facet/FacetItem.vue'

export default defineComponent({
  name: 'FacetItemsCategory',
  components: { FacetItem },

  props: {
    component: {
      type: String,
      required: true,
    },
    axiosParams: {
      type: undefined as unknown as PropType<Record<string, any> | null>,
      required: true,
    },

    filtersState: {
      type: Object as PropType<Record<string, string[]>>,
      required: true,
    },
    category: {
      type: String,
      required: true,
    },
    items: {
      type: Array as PropType<Item[]>,
      required: true,
    },
    expanded: {
      type: Boolean,
      required: true,
    },
  },

  setup(props, ctx) {
    const pinnedFacetMan = usePinnedFacetManager()
    const expandedInternal = shallowRef(props.expanded)

    function pinFacet(attr: string) {
      pinnedFacetMan.add(attr).then(() => {
        ctx.emit('update:pinned')
      })
    }

    function unpinFacet(attr: string) {
      pinnedFacetMan.remove(attr).then(() => {
        ctx.emit('update:pinned')
      })
    }

    function itemExpanded(item: Item, index: number): boolean {
      switch (props.category) {
        case Category.All:
        case Category.Pinned:
          return index < 3
        default:
          return item.value in props.filtersState
      }
    }

    return {
      expandedInternal,
      itemExpanded,

      pinnedFacetMan,
      pinFacet,
      unpinFacet,
    }
  },
})
