
import { defineComponent, computed, PropType } from 'vue'

// Composables
import { MonitorState } from '@/alerting/use-monitors'

export default defineComponent({
  name: 'MonitorStateAvatar',

  props: {
    state: {
      type: String as PropType<MonitorState>,
      required: true,
    },
  },

  setup(props) {
    const attrs = computed(() => {
      switch (props.state) {
        case MonitorState.Active:
          return { color: 'success' }
        case MonitorState.Firing:
          return { color: 'error', dark: true }
        default:
          return { color: 'grey' }
      }
    })

    return { attrs }
  },
})
