
import { truncate } from 'lodash-es'
import { defineComponent, computed, PropType } from 'vue'

// Composables
import { UseOrder } from '@/use/order'
import { exploreAttr } from '@/use/uql'
import { AxiosParams } from '@/use/watch-axios'
import { TableItem } from '@/metrics/use-query'

// Components
import SparklineChart from '@/components/SparklineChart.vue'
import TimeseriesTableRow from '@/metrics/TimeseriesTableRow.vue'

// Utilities
import { StyledColumnInfo } from '@/metrics/types'
import { AttrKey } from '@/models/otel'

export default defineComponent({
  name: 'TimeseriesTable',
  components: { SparklineChart, TimeseriesTableRow },

  props: {
    loading: {
      type: Boolean,
      default: false,
    },
    items: {
      type: Array as PropType<TableItem[]>,
      required: true,
    },
    itemsPerPage: {
      type: Number,
      default: 10,
    },
    columns: {
      type: Array as PropType<StyledColumnInfo[]>,
      required: true,
    },
    order: {
      type: Object as PropType<UseOrder>,
      required: true,
    },
    axiosParams: {
      type: Object as PropType<AxiosParams>,
      default: undefined,
    },
  },

  setup(props) {
    const grouping = computed((): string[] => {
      return props.columns.filter((col) => col.isGroup).map((col) => col.name)
    })

    const aggColumns = computed(() => {
      return props.columns.filter((col) => !col.isGroup)
    })

    const headers = computed(() => {
      const headers = []
      for (let colName of grouping.value) {
        headers.push({ text: colName, value: colName, sortable: true })
      }
      for (let col of aggColumns.value) {
        headers.push({ text: col.name, value: col.name, sortable: true, align: 'start' })
      }
      return headers
    })

    function spanListRouteFor(item: TableItem) {
      const query = exploreAttr(AttrKey.spanGroupId)
      const groupId = item[AttrKey.spanGroupId]
      return {
        name: 'SpanList',
        query: {
          query: `${query} | where ${AttrKey.spanGroupId} = ${groupId}`,
        },
      }
    }

    return { AttrKey, grouping, aggColumns, headers, spanListRouteFor, eventOrSpanName }
  },
})

function eventOrSpanName(item: Record<string, any>, maxLength = 120) {
  const eventName = item[AttrKey.spanEventName]
  if (eventName) {
    return truncate(eventName, { length: maxLength })
  }
  return truncate(item[AttrKey.spanName], { length: maxLength })
}
