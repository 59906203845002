
import { defineComponent, computed, PropType } from 'vue'

// Utilities
import { Period } from '@/models/period'
import { formatDistance } from 'date-fns'

export default defineComponent({
  name: 'PeriodPickerMenu',

  props: {
    value: {
      type: Number,
      required: true,
    },
    periods: {
      type: Array as PropType<Period[]>,
      required: true,
    },
  },

  setup(props, { emit }) {
    const activePeriod = computed((): Period | undefined => {
      const period = props.periods.find((p) => p.milliseconds === props.value)

      if (!period) {
        return {
          text: formatDistance(0, props.value),
          milliseconds: props.value,
        }
      }

      return period
    })

    function onChange(ms: number | undefined) {
      if (ms) {
        emit('input', ms)
      }
    }

    return { activePeriod, onChange }
  },
})
