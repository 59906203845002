
import { defineComponent, shallowRef, set, del, computed, watch, PropType } from 'vue'

// Composables
import { UseDateRange } from '@/use/date-range'
import { useUql } from '@/use/uql'
import { useActiveMetrics } from '@/metrics/use-metrics'
import { useGridColumnManager } from '@/metrics/use-dashboards'
import { useTableQuery } from '@/metrics/use-query'

// Components
import UnitPicker from '@/components/UnitPicker.vue'
import MetricsPicker from '@/metrics/MetricsPicker.vue'
import MetricsQueryBuilder from '@/metrics/query/MetricsQueryBuilder.vue'
import TimeseriesTable from '@/metrics/TimeseriesTable.vue'

// Utilities
import { requiredRule } from '@/util/validation'
import { TableGridColumn } from '@/metrics/types'

export default defineComponent({
  name: 'DashGridColumnTableForm',
  components: {
    UnitPicker,
    MetricsPicker,
    MetricsQueryBuilder,
    TimeseriesTable,
  },

  props: {
    dateRange: {
      type: Object as PropType<UseDateRange>,
      required: true,
    },
    gridColumn: {
      type: Object as PropType<TableGridColumn>,
      required: true,
    },
    editable: {
      type: Boolean,
      default: false,
    },
  },

  setup(props, ctx) {
    const uql = useUql()

    const metricsPicker = shallowRef()
    const form = shallowRef()
    const isValid = shallowRef(false)
    const rules = { name: [requiredRule] }
    const gridColumnMan = useGridColumnManager()

    const activeMetrics = useActiveMetrics(computed(() => props.gridColumn.params.metrics))

    const tableQuery = useTableQuery(
      () => {
        if (!props.gridColumn.params.metrics.length || !props.gridColumn.params.query) {
          return undefined
        }

        return {
          ...props.dateRange.axiosParams(),
          metric: props.gridColumn.params.metrics.map((m) => m.name),
          alias: props.gridColumn.params.metrics.map((m) => m.alias),
          query: props.gridColumn.params.query,
        }
      },
      computed(() => props.gridColumn.params.columnMap),
    )

    watch(
      () => tableQuery.columns,
      () => {
        const unused = new Set(Object.keys(props.gridColumn.params.columnMap))

        for (let col of tableQuery.columns) {
          if (col.isGroup) {
            continue
          }
          unused.delete(col.name)
          if (col.name in props.gridColumn.params.columnMap) {
            continue
          }
          set(props.gridColumn.params.columnMap, col.name, {
            unit: col.unit,
          })
        }

        for (let colName of unused.values()) {
          del(props.gridColumn.params.columnMap, colName)
        }
      },
    )

    watch(
      () => props.gridColumn.params.query,
      (query) => {
        uql.query = query
      },
      { immediate: true },
    )

    watch(
      () => uql.query,
      (query) => {
        props.gridColumn.params.query = query
      },
    )

    watch(
      () => tableQuery.query,
      (query) => {
        if (query) {
          uql.setQueryInfo(query)
        }
      },
      { immediate: true },
    )

    function submit() {
      const r1 = metricsPicker.value.validate()
      const r2 = form.value.validate()
      if (!r1 || !r2) {
        return
      }

      gridColumnMan.save(props.gridColumn).then((gridColumn) => {
        ctx.emit('click:save', gridColumn)
      })
    }

    return {
      uql,

      metricsPicker,
      form,
      isValid,
      rules,
      gridColumnMan,
      submit,

      activeMetrics,
      tableQuery,
    }
  },
})
