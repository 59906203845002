
import { defineComponent, PropType } from 'vue'

// Composables
import { Facet, UseFacetedSearch } from '@/use/faceted-search'

export default defineComponent({
  name: 'SearchFacets',

  props: {
    facetedSearch: {
      type: Object as PropType<UseFacetedSearch>,
      required: true,
    },
    facets: {
      type: Array as PropType<Facet[]>,
      required: true,
    },
  },
})
