
import { defineComponent, PropType } from 'vue'

// Composables
import { UseOrder } from '@/use/order'
import { Alert } from '@/alerting/use-alerts'

// Components
import AlertsTableRow from '@/alerting/AlertsTableRow.vue'

export default defineComponent({
  name: 'AlertsTable',
  components: {
    AlertsTableRow,
  },

  props: {
    alerts: {
      type: Array as PropType<Alert[]>,
      required: true,
    },
    order: {
      type: Object as PropType<UseOrder>,
      required: true,
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },

  setup() {
    return {}
  },
})
