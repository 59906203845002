
import { defineComponent, shallowRef, set, del, computed, watch, PropType } from 'vue'

// Composables
import { UseDateRange } from '@/use/date-range'
import { useUql } from '@/use/uql'
import { useGridColumnManager } from '@/metrics/use-dashboards'
import { useActiveMetrics } from '@/metrics/use-metrics'
import { useTimeseries, useStyledTimeseries } from '@/metrics/use-query'

// Components
import BtnSelectMenu from '@/components/BtnSelectMenu.vue'
import UnitPicker from '@/components/UnitPicker.vue'
import GridColumnChart from '@/metrics/GridColumnChart.vue'
import MetricsPicker from '@/metrics/MetricsPicker.vue'
import MetricsQueryBuilder from '@/metrics/query/MetricsQueryBuilder.vue'
import TimeseriesStyleCard from '@/metrics/TimeseriesStyleCard.vue'

// Utilities
import { EventBus } from '@/models/eventbus'
import { requiredRule } from '@/util/validation'
import {
  defaultTimeseriesStyle,
  updateColumnMap,
  ChartGridColumn,
  ChartKind,
  Timeseries,
  StyledTimeseries,
  TimeseriesStyle,
  LegendType,
  LegendPlacement,
  LegendValue,
} from '@/metrics/types'

export default defineComponent({
  name: 'DashGridColumnChartForm',
  components: {
    BtnSelectMenu,
    GridColumnChart,
    UnitPicker,
    MetricsPicker,
    MetricsQueryBuilder,
    TimeseriesStyleCard,
  },

  props: {
    dateRange: {
      type: Object as PropType<UseDateRange>,
      required: true,
    },
    tableGrouping: {
      type: Array as PropType<string[]>,
      required: true,
    },
    gridColumn: {
      type: Object as PropType<ChartGridColumn>,
      required: true,
    },
    editable: {
      type: Boolean,
      default: false,
    },
  },

  setup(props, ctx) {
    const eventBus = new EventBus()
    const uql = useUql()

    const metricsPicker = shallowRef()
    const form = shallowRef()
    const isValid = shallowRef(false)
    const rules = { name: [requiredRule] }
    const gridColumnMan = useGridColumnManager()

    const chartKindItems = computed(() => {
      return [
        { text: 'Line chart', value: ChartKind.Line },
        { text: 'Area chart', value: ChartKind.Area },
        { text: 'Stacked area', value: ChartKind.StackedArea },
        { text: 'Bar chart', value: ChartKind.Bar },
        { text: 'Stacked bar', value: ChartKind.StackedBar },
      ]
    })
    const legendValueItems = computed(() => {
      return [
        { text: 'Avg', value: LegendValue.Avg },
        { text: 'Last', value: LegendValue.Last },
        { text: 'Min', value: LegendValue.Min },
        { text: 'Max', value: LegendValue.Max },
      ]
    })

    const activeMetrics = useActiveMetrics(computed(() => props.gridColumn.params.metrics))

    const timeseries = useTimeseries(() => {
      if (!props.gridColumn.params.metrics.length || !props.gridColumn.params.query) {
        return undefined
      }

      return {
        ...props.dateRange.axiosParams(),
        metric: props.gridColumn.params.metrics.map((m) => m.name),
        alias: props.gridColumn.params.metrics.map((m) => m.alias),
        query: props.gridColumn.params.query,
      }
    })

    const styledTimeseries = useStyledTimeseries(
      computed(() => timeseries.items),
      computed(() => props.gridColumn.params.columnMap),
      computed(() => props.gridColumn.params.timeseriesMap),
    )
    const currentTimeseries = shallowRef<StyledTimeseries[]>()
    const activeTimeseries = computed(() => {
      if (currentTimeseries.value) {
        return currentTimeseries.value
      }
      return styledTimeseries.value
    })

    watch(
      () => props.gridColumn.params.query,
      (query) => {
        uql.query = query
      },
      { immediate: true },
    )

    watch(
      () => uql.query,
      (query) => {
        props.gridColumn.params.query = query
      },
    )

    watch(
      () => timeseries.query,
      (query) => {
        if (query) {
          uql.setQueryInfo(query)
        }
      },
      { immediate: true },
    )

    watch(
      () => timeseries.columns,
      (columns) => {
        updateColumnMap(props.gridColumn.params.columnMap, columns)
      },
    )

    function submit() {
      const r1 = metricsPicker.value.validate()
      const r2 = form.value.validate()
      if (!r1 || !r2) {
        return
      }

      gridColumnMan.save(props.gridColumn).then((gridColumn) => {
        ctx.emit('click:save', gridColumn)
      })
    }

    function getTimeseriesStyle(ts: StyledTimeseries): TimeseriesStyle {
      if (!(ts.name in props.gridColumn.params.timeseriesMap)) {
        set(props.gridColumn.params.timeseriesMap, ts.name, {
          ...defaultTimeseriesStyle(),
          color: ts.color,
        })
      }
      return props.gridColumn.params.timeseriesMap[ts.name]
    }
    function resetTimeseriesStyle(ts: Timeseries) {
      del(props.gridColumn.params.timeseriesMap, ts.name)
    }

    return {
      LegendType,
      LegendPlacement,

      eventBus,
      uql,

      metricsPicker,
      form,
      isValid,
      rules,
      gridColumnMan,
      chartKindItems,
      legendValueItems,
      submit,

      activeMetrics,
      timeseries,
      styledTimeseries,
      currentTimeseries,
      activeTimeseries,

      getTimeseriesStyle,
      resetTimeseriesStyle,
    }
  },
})
