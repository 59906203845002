
import { defineComponent, computed, PropType } from 'vue'

// Composables
import { UseDateRange } from '@/use/date-range'
import { useProject } from '@/org/use-projects'

// Components
import DateRangePicker from '@/components/date/DateRangePicker.vue'
import CollectorTabs from '@/components/CollectorTabs.vue'
import DistroIcons from '@/components/DistroIcons.vue'
import SoftwareIcons from '@/components/SoftwareIcons.vue'

export default defineComponent({
  name: 'HelpCard',
  components: {
    DateRangePicker,
    CollectorTabs,
    DistroIcons,
    SoftwareIcons,
  },

  props: {
    loading: {
      type: Boolean,
      default: false,
    },
    dateRange: {
      type: Object as PropType<UseDateRange>,
      default: undefined,
    },
  },

  setup() {
    const project = useProject()

    const vectorConfig = computed(() => {
      return `
[sources.in]
type = "file"
include = ["/var/log/syslog"]

[sinks.uptrace]
type = "http"
method = "post"
inputs = ["in"]
encoding.codec = "json"
framing.method = "newline_delimited"
compression = "gzip"
uri = "${project.http.endpoint}/api/v1/vector/logs"
request.headers.uptrace-dsn = "${project.http.dsn}"
      `.trim()
    })

    const zipkinCurl = computed(() => {
      return `
curl -X POST '${project.http.endpoint}/api/v2/spans' \\
  -H 'Content-Type: application/json' \\
  -H 'uptrace-dsn: ${project.http.dsn}' \\
  -d @spans.json
      `.trim()
    })

    return { project, vectorConfig, zipkinCurl }
  },
})
