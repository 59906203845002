
import { defineComponent, shallowRef, computed } from 'vue'

// Composables
import { useTitle } from '@vueuse/core'
import { useRouteQuery } from '@/use/router'
import { useForceReload } from '@/use/force-reload'
import { useFacetedSearch } from '@/use/faceted-search'
import { useAlerts, useAlertSelection, Alert } from '@/alerting/use-alerts'

// Components
import ForceReloadBtn from '@/components/date/ForceReloadBtn.vue'
import AlertsSidebar from '@/alerting/AlertsSidebar.vue'
import AlertSelection from '@/alerting/AlertSelection.vue'
import AlertOrderPicker from '@/alerting/AlertOrderPicker.vue'
import AlertsTable from '@/alerting/AlertsTable.vue'
import AlertCard from '@/alerting/AlertCard.vue'

export default defineComponent({
  name: 'AlertList',
  components: {
    ForceReloadBtn,
    AlertsSidebar,
    AlertSelection,
    AlertOrderPicker,
    AlertsTable,
    AlertCard,
  },

  setup() {
    useTitle('Alerts')

    const dialog = shallowRef(false)
    const activeAlertId = shallowRef<string>()

    const { forceReloadParams } = useForceReload()

    const facetedSearch = useFacetedSearch()
    const alerts = useAlerts(
      computed(() => {
        const params: Record<string, any> = {
          ...forceReloadParams.value,
          ...facetedSearch.axiosParams,
        }

        return params
      }),
    )
    const selection = useAlertSelection(
      computed(() => {
        return alerts.items
      }),
    )

    useRouteQuery().sync({
      fromQuery(queryParams) {
        if (Object.keys(queryParams).length === 0) {
          facetedSearch.selected['state'] = ['open']
        }
      },
    })

    function showAlert(alert: Alert) {
      activeAlertId.value = alert.id
      dialog.value = true
    }

    return {
      dialog,
      activeAlertId,
      showAlert,

      facetedSearch,
      alerts,
      selection,
    }
  },
})
