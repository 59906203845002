
import { defineComponent, shallowRef } from 'vue'

// Composables
import { useRouter } from '@/use/router'
import { useDashManager } from '@/metrics/use-dashboards'

// Utilities
import { requiredRule } from '@/util/validation'

export default defineComponent({
  name: 'DashNewForm',

  setup(props, ctx) {
    const { route } = useRouter()

    const name = shallowRef('')
    const isValid = shallowRef(false)
    const rules = {
      name: [requiredRule],
    }

    const dashMan = useDashManager()

    function create() {
      if (!isValid.value) {
        return
      }

      const { projectId } = route.value.params
      dashMan
        .create({
          name: name.value,
          projectId: parseInt(projectId, 10),
        })
        .then((dash) => {
          name.value = ''
          ctx.emit('create', dash)
        })
    }

    return {
      name,
      isValid,
      rules,

      dashMan,
      create,
    }
  },
})
