
import { defineComponent, shallowRef, PropType } from 'vue'

// Composables
import { useSavedViewManager, SavedView, UseSavedViews } from '@/tracing/views/use-saved-views'

export default defineComponent({
  name: 'SavedViewList',

  props: {
    loading: {
      type: Boolean,
      required: true,
    },
    items: {
      type: Array as PropType<SavedView[]>,
      required: true,
    },
    editMode: {
      type: Boolean,
      default: false,
    },
    views: {
      type: Object as PropType<UseSavedViews>,
      required: true,
    },
  },

  setup(props) {
    const viewMan = useSavedViewManager()
    const pendingView = shallowRef<SavedView>()

    function pinView(view: SavedView) {
      pendingView.value = view
      viewMan.pin(view.id).finally(() => {
        pendingView.value = undefined
        props.views.reload()
      })
    }

    function unpinView(view: SavedView) {
      pendingView.value = view
      viewMan.unpin(view.id).finally(() => {
        pendingView.value = undefined
        props.views.reload()
      })
    }

    function deleteView(view: SavedView) {
      pendingView.value = view
      viewMan.del(view.id).finally(() => {
        pendingView.value = undefined
        props.views.reload()
      })
    }

    function tabName(item: SavedView): string {
      switch (item.route) {
        case 'SpanGroupList':
        case 'EventGroupList':
          return 'Groups'
        case 'SpanList':
          return 'Spans'
        case 'EventList':
          return 'Events'
        case 'SpanTimeseries':
        case 'EventTimeseries':
          return 'Timeseries'
        default:
          return ''
      }
    }

    function subtitle(item: SavedView): string {
      return item.query.query ?? ''
    }

    function querySystem(query: Record<string, any>): string {
      const system = query.system
      if (Array.isArray(system) && system.length) {
        return system[0]
      }
      return system
    }

    return {
      viewMan,
      pendingView,
      pinView,
      unpinView,
      deleteView,

      tabName,
      subtitle,
      querySystem,
    }
  },
})
