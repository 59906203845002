
import { defineComponent, computed, PropType } from 'vue'

// Composables
import { useTitle } from '@vueuse/core'
import { UseDateRange } from '@/use/date-range'
import { useUql } from '@/use/uql'
import { useProject } from '@/org/use-projects'
import { useSystems } from '@/tracing/system/use-systems'

// Components
import DateRangePicker from '@/components/date/DateRangePicker.vue'
import SystemPicker from '@/tracing/system/SystemPicker.vue'
import QuickSpanFilter from '@/tracing/query/QuickSpanFilter.vue'
import SystemQuickMetrics from '@/tracing/system/SystemQuickMetrics.vue'
import HelpCard from '@/tracing/HelpCard.vue'

// Utilities
import { isErrorSystem, AttrKey } from '@/models/otel'
import { DAY } from '@/util/fmt/date'

export default defineComponent({
  name: 'Overview',
  components: { DateRangePicker, SystemPicker, QuickSpanFilter, HelpCard, SystemQuickMetrics },

  props: {
    dateRange: {
      type: Object as PropType<UseDateRange>,
      required: true,
    },
  },

  setup(props) {
    useTitle('Overview')

    props.dateRange.syncQueryParams()

    const project = useProject()
    const uql = useUql()
    uql.syncQueryParams()

    const systems = useSystems(() => {
      return {
        ...props.dateRange.axiosParams(),
        ...uql.axiosParams(),
      }
    })
    systems.syncQueryParams()

    const axiosParams = computed(() => {
      return {
        ...props.dateRange.axiosParams(),
        ...uql.axiosParams(),
        ...systems.axiosParams(),
      }
    })

    const chosenSystems = computed((): string[] => {
      if (props.dateRange.duration > 3 * DAY) {
        return []
      }

      const chosen = []
      for (let system of systems.items) {
        if (isErrorSystem(system.system)) {
          chosen.push(system.system)
        }
      }
      return chosen
    })

    return {
      AttrKey,

      uql,
      project,
      systems,
      axiosParams,

      chosenSystems,
    }
  },
})
