
import { defineComponent, shallowRef } from 'vue'

// Components
import SpanQueryExample from '@/tracing/query/SpanQueryExample.vue'

export default defineComponent({
  name: 'SpanQueryHelpDialog',
  components: { SpanQueryExample },

  setup() {
    const dialog = shallowRef(false)
    return { dialog }
  },
})
