
import { defineComponent, PropType } from 'vue'

// Composables
import { useConfirm } from '@/use/confirm'
import { useAlertManager, UseAlertSelection } from '@/alerting/use-alerts'

export default defineComponent({
  name: 'AlertsSelection',

  props: {
    selection: {
      type: Object as PropType<UseAlertSelection>,
      required: true,
    },
  },

  setup(props, ctx) {
    const confirm = useConfirm()
    const alertMan = useAlertManager()

    const closeAlerts = function () {
      alertMan.close(props.selection.openAlerts).then(() => {
        props.selection.reset()
        ctx.emit('change')
      })
    }

    const openAlerts = function () {
      alertMan.open(props.selection.closedAlerts).then(() => {
        props.selection.reset()
        ctx.emit('change')
      })
    }

    const closeAllAlerts = function () {
      confirm
        .open('Close all', 'Do you really want to close all alerts?')
        .then(() => {
          alertMan.closeAll().then(() => {
            props.selection.reset()
            ctx.emit('change')
          })
        })
        .catch(() => {})
    }

    return {
      alertMan,
      closeAlerts,
      openAlerts,
      closeAllAlerts,
    }
  },
})
