
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'PageToolbar',

  props: {
    loading: {
      type: Boolean,
      default: false,
    },
    fluid: {
      type: Boolean,
      default: false,
    },
    color: {
      type: String,
      default: 'light-blue lighten-5',
    },
  },

  setup() {
    return {}
  },
})
