
import { upperFirst } from 'lodash-es'
import { defineComponent, shallowRef, watch } from 'vue'

// Composables
import { useTitle } from '@vueuse/core'
import { useAxios } from '@/use/axios'
import { useRouter } from '@/use/router'
import { useUser, useSso } from '@/org/use-users'

const requiredRule = (v: string) => (v && v.length != 0) || 'Field is required'

export default defineComponent({
  name: 'Login',

  setup() {
    useTitle('Log in')

    const { router } = useRouter()
    const user = useUser()
    const sso = useSso()

    const isValid = shallowRef(false)
    const rules = {
      email: [requiredRule],
      password: [requiredRule],
    }
    const error = shallowRef('')

    const email = shallowRef('uptrace@localhost')
    const password = shallowRef('uptrace')

    const { loading, request } = useAxios()

    watch(
      () => user.current,
      () => {
        if (user.isAuth) {
          router.push({ name: 'Home' }).catch(() => {})
        }
      },
    )

    function submit() {
      login()
        .then(() => {
          error.value = ''
          user.reload()
        })
        .catch((err) => {
          const msg = err.response?.data?.message
          if (msg) {
            error.value = upperFirst(msg)
          }
        })
    }

    function login() {
      const data = {
        email: email.value,
        password: password.value,
      }

      const url = `/api/v1/users/login`
      return request({ method: 'POST', url, data })
    }

    return {
      sso,

      isValid,
      rules,
      error,

      email,
      password,

      loading,
      submit,
    }
  },
})
